// active import of this file is required to ensure correctly sized icons in SSR
// https://github.com/FortAwesome/angular-fontawesome/issues/48
// @import 'node_modules/@fortawesome/fontawesome-svg-core/styles.css'

.svg-inline--fa {
	vertical-align: -0.125em;
}
  
fa-icon svg {
	display: inline-block;
	font-size: inherit;
	height: 1em;
}
  
fa-icon .fa-2x{
	font-size: 2em;
}