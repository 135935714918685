
// neutral base colors
$contentColor:#fff!default;

// $contentSoftColor:#ebeaec!default;
// $backgroundColor:#d3d0d6!default;
// $backgroundSoftColor:#f1f1f2!default;

// $contentSoftColor:#e8e8e8!default;
$contentSoftColor:#f3f3f4!default;
$backgroundColor:#eaeaee!default;
$backgroundSoftColor:#cdced2!default;

$deepGreyColor:#636466!default;
$greyColor:#abaeb5!default;
$softGreyColor:#dcdee2!default;

// text colors
$textColor:#333333!default;
$textSoftColor:lighten($textColor,25%)!default;
$invertedTextColor:#fff!default;

// colorful colors
$primaryColor:#136A8A!default;
$primaryTextColor:#ffffff!default;
$primaryDarkColor:#365e83!default;
$primaryDarkerColor:#153650!default;
$secondaryColor:#8299b3!default;
$contrastColor:#752540!default;
$accentColor:$contrastColor!default;
$accentTextColor:#ffffff!default;

$highlightColor:#00dcff!default;
$highlightBgColor:$highlightColor!default;
$highlightTextColor:#fff!default;

$disabledBgColor: #e2e3e4!default;
$disabledTextColor: #909098!default;
$disabledBorderColor: transparent!default;

$validationMessageBgColor:#fac9cc;
$validationMessageTextColor:#c2232b;

$inputFocusBorderColor:$primaryColor!default;
$datatableEvenRowBgColor:#fdfafc!default;
$datatableHoverBgColor:#e3e1e2!default;

$fontFamily:"Open Sans","Helvetica Neue",Arial, sans-serif!default;
$fontWeight:400!default;
$fontSize:16px!default;
$fontSizeSmall:0.8rem!default;
$lineHeight:1.5!default;

$linkFontWeight:400!default;
$smallFontWeight:400!default;
$smallLinkFontWeight:700!default;
$buttonFontWeight:400!default;

$headlinesFontFamily:"Open Sans","Helvetica Neue",Arial, sans-serif!default;
$headlinesFontWeight:400!default;
$headlinesTextColor:inherit!default;
$headlinesLineHeight:1.3!default;
$headlinesTextTransform:none!default;

$fontSizeH1:2.0rem;
$fontSizeH2:1.6rem;
$fontSizeH3:1.4rem;
$fontSizeH4:1.2rem;
$fontSizeH5:1.1rem;
$fontSizeH6:1.0rem;

$borderRadius:4px!default;
$dividerColor:#d8dae2!default;
$transitionDuration:.3s!default;

$sidebarTopStartBgColor:#00dcff!default;
$sidebarTopEndBgColor:#00dcff!default;
$sidebarAnchorColor:#ffffff!default;



$topbarHeight:64px!default;
$layoutBreakpoint:960px!default;
$bottombarHeight:42px!default;
$bottombarMarginTop:20px!default;

$sidebarWidth:264px;
$sidebarCollapsedWidth:64px;
$sidebarBreakpoint:1280px!default;


//light sidebar
// $lightSidebarBgColor:#f2f2f7!default;
// $lightSidebarMenuitemIconBgColor:#d9d9de!default;
// $lightSidebarMenuitemTextColor:#575867!default;
// $lightSidebarMenuitemHoverBgColor:#d9d9de!default;
// $lightSidebarSubmenuitemHoverBgColor:#d9d9de!default;
// $lightSidebarActiveMenuitemBgColor:#ffffff!default;
// $lightSidebarSubmenuBgColor:#ffffff!default;


//misc
$rightPanelBgColor:#ffffff!default;
$smallBreakpoint:420px!default;
$mobileBreakpoint:768px!default;
$tabletBreakpoint:960px!default;
$largeBreakpoint:1280px!default;
$xlargeBreakpoint:1800px!default;

$contentMobileMaskBgColor:rgba(0,0,0,.5)!default;

$gridSpacing:25px !default;
$gridSpacingLarge:40px !default;
$gridSpacingSmall:10px !default;





$adminContentBorder:10px solid $backgroundSoftColor;
$adminContentBorderColor:transparent;
$adminContentPadding:0;
$adminContentMargin:0;
$adminContentBackground:$backgroundSoftColor;





/* ------------------------------------------------------------------------ */


$fieldWrapperMargin:8px;

//input field (e.g. input-text, spinner, inputmask)
$inputPadding:10px;
$inputBorder:1px solid #a6a6a6;
$inputBorderRadius:4px;
$inputBorderColor: #ced3d9;
$inputHoverBorderColor:#212121;
$inputFocusShadow:none;
$inputErrorBorderColor:#a80000;
$inputPlaceholderTextColor:#666666;
$inputTransition:border-color $transitionDuration, background-color $transitionDuration;
$inputDisabledBgColor: #e4e4e4!default;
$inputDisabledTextColor: #666!default;
$inputDisabledBorderColor: #aaa!default;

//input groups
$inputGroupAddonBorder:1px solid #a6a6a6;
$inputGroupAddonBorderColor: #a6a6a6;
$inputGroupAddonBgColor:#eaeaea;
$inputGroupAddonTextColor:$textSoftColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth:12em;
$inputListMaxHeight:220px;
$inputListBgColor:#e4e4e4;
$inputListBorder:none;
$inputListBorderColor:transparent;
$inputListPadding:0;
$inputListItemPadding:6px 15px;
$inputListItemBgColor:#f5f5f5;
$inputListItemTextColor:#333333;
$inputListItemHoverBgColor:#eaeaea;
$inputListItemHoverTextColor:#333333;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightTextColor;
$inputListItemBorder:0 none;
$inputListItemBorderColor:transparent;
$inputListItemMargin:0;
$inputListItemDividerColor:#d8dae2;
$inputListItemIconColor:#444;

$inputListHeaderPaddingTop:6px;
$inputListHeaderPaddingLeft:15px;
$inputListHeaderPaddingRight:15px;
$inputListHeaderPaddingBottom:6px;
$inputListHeaderMargin:0;
$inputListHeaderBgColor:#ffffff;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid #eaeaea;
$inputListHeaderBorderColor:#eaeaea;
$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textColor;
$inputListHeaderCloseIconHoverColor:$primaryColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;
$inputListDragHandleBgColor:transparent;

$orderListItemBorderColor:#bababa;

//inputs with panels (e.g. password)
$inputContentPanelPadding:12px;
$inputContentPanelBgColor:#ffffff;
$inputContentPanelTextColor:$textColor;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder:1px solid #c8c8c8;
$inputOverlayBorderColor: #c8c8c8;

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor:$textSoftColor;

// ------ button -----------------------------------------------------------------------
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em;
$buttonWithRightIconPadding: 0.429em 1em;
$buttonTextOnlyPaddingSmall:0.5em 0.7em;
$buttonWithLeftIconPaddingSmall:0.5em 0.7em;
$buttonWithRightIconPaddingSmall:0.5em 1.4em;
$buttonIconOnlyPaddingSmall:0.5em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.357em;
$buttonIconToTextMargin:0.5em;

$buttonBorder:1px solid $primaryColor;
$buttonBorderWidth:1px;
$buttonBorderColor:$primaryColor;
$buttonBgColor:$primaryColor;
$buttonTextColor:$primaryTextColor;
$buttonIconColor:$primaryTextColor;
$buttonHoverBgColor:$primaryDarkColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverIconColor:$primaryTextColor;
$buttonHoverBorderColor:$primaryDarkColor;
$buttonActiveBgColor:$primaryDarkerColor;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveIconColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkerColor;
$buttonFocusBorderColor:$primaryDarkerColor;
$buttonFocusOutline:0 none;
$buttonFocusOutlineOffset:0px;

$buttonTransition:background-color $transitionDuration, border-color $transitionDuration;
$raisedButtonShadow:0 2px 3px 0 rgba(0, 0, 0, 0.35);
$buttonBorderRadius:$borderRadius!default;
$roundedButtonBorderRadius:25px;

$outlineButtonBorder:1px solid #aaa;
$contrastButtonBorder:1px solid #aaa;
$secondaryButtonBorder:1px solid #dadada;


// ------ // button -----------------------------------------------------------------------

//input-checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration;

//togglebutton
$toggleButtonBorder:1px solid #dadada;
$toggleButtonHoverBgColor:#c8c8c8;
$toggleButtonHoverBorderColor:#c8c8c8;
$toggleButtonHoverTextColor:#333333;
$toggleButtonHoverIconColor:#212121;
$toggleButtonActiveBgColor:$primaryColor;
$toggleButtonActiveBorderColor:$primaryColor;
$toggleButtonActiveTextColor:$primaryTextColor;
$toggleButtonActiveIconColor:$primaryTextColor;
$toggleButtonActiveHoverBgColor:$primaryDarkColor;
$toggleButtonActiveHoverBorderColor:$primaryDarkColor;
$toggleButtonActiveHoverTextColor:$primaryTextColor;
$toggleButtonActiveHoverIconColor:$primaryTextColor;
$toggleButtonFocusOutline:0 none;
$toggleButtonFocusBgColor:#c8c8c8;
$toggleButtonFocusBorderColor:#c8c8c8;
$toggleButtonFocusTextColor:#333333;
$toggleButtonFocusIconColor:#212121;
$toggleButtonActiveFocusBgColor:$primaryDarkerColor;
$toggleButtonActiveFocusBorderColor:$primaryDarkerColor;
$toggleButtonActiveFocusTextColor:$primaryTextColor;
$toggleButtonActiveFocusIconColor:$primaryTextColor;

//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor:#eaeaea;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
// $ratingTransition:color $transitionDuration;
// $ratingCancelIconColor:#e4018d;
// $ratingCancelHoverIconColor:#b5019f;
// $ratingIconFontSize:20px;
// $ratingStarIconColor:$textColor;
// $ratingStarIconHoverColor:$primaryColor;

//slider
// $sliderBgColor:#c8c8c8;
// $sliderBorder:0 none;
// $sliderHeight:.286em;
// $sliderWidth:0.286em;
// $sliderHandleWidth:1.143em;
// $sliderHandleHeight:1.143em;
// $sliderHandleBgColor:#ffffff;
// $sliderHandleBorder:2px solid #666666;
// $sliderHandleBorderRadius:100%;
// $sliderHandleHoverBorder:2px solid $primaryColor;
// $sliderHandleHoverBgColor:2px solid #666666;
// $sliderHandleTransition:border-color $transitionDuration;
// $sliderRangeBgColor:$primaryColor;

//calendar
// $calendarWidth:20em;
// $calendarNavIconColor:#a6a6a6;
// $calendarNavIconHoverColor:$primaryColor;
// $calendarNavIconTransition:color $transitionDuration;
// $calendarPadding:0.857em;
// $calendarTableMargin:0.857em 0 0 0;
// $calendarCellPadding:0.286em;
// $calendarCellDatePadding:0.286em;
// $calendarCellDateWidth:1.714em;
// $calendarCellDateHeight:1.714em;
// $calendarCellDateHoverBgColor:#eaeaea;
// $calendarCellDateBorderRadius:$borderRadius;
// $calendarCellDateSelectedBgColor:$primaryColor;
// $calendarCellDateSelectedTextColor:$primaryTextColor;
// $calendarCellDateTodayBgColor:#d0d0d0;
// $calendarCellDateTodayTextColor:$textColor;
// $calendarTimePickerDivider: 1px solid #d8dae2;
// $calendarTimePickerPadding:0.857em;
// $calendarTimePickerIconColor:$textColor;
// $calendarTimePickerIconFontSize:1.286em;
// $calendarTimePickerTimeFontSize:1.286em;
// $calendarTimePickerIconHoverColor:$primaryColor;

//spinner
$spinnerButtonWidth:2em;

//input switch
$inputSwitchBorderRadius:0.9em;
$inputSwitchTransition:border-color $transitionDuration;
$inputSwitchHandleTransition:background-color $transitionDuration;


//container common
$containerHeaderBorder:1px solid $primaryColor;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder:1px solid #c8c8c8;
$panelHeaderBorderWidth:1px 0 0 0;
$panelHeaderAltTextColor: #fff;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:400;
$panelHeaderFontSize:1.2rem;
$panelHeaderPadding:8px 10px;
$panelContentBorder:1px solid #c8c8c8;
$panelContentPadding:0.571em 1em;
$panelFooterHeight:50px;
$panelFooterBorder:1px solid #c8c8c8;
$panelFooterBorderWidth:1px 0 0 0;
$panelFooterPadding:8px 10px;

//accordion
$accordionSpacing:4px;

// tabmenu

// used in multiple situations, bottom bar as well as tab menu! set ui-tabMenu --tabMenuItemPadding css var to adjust
$tabMenuItemPadding:8px 10px; 
$tabMenuBorder:none;


// tabview
$tabHeaderSpacing: 0px;
$tabHeaderBorder:1px solid #c8c8c8;
$tabHeaderIconTransition:color $transitionDuration;
$tabHeaderFontWeight:400;
$tabHeaderFontSize:1.2rem;
$tabHeaderPadding:0.571em 1em;

//scrollpanel
$scrollPanelHandleBgColor:#dadada;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBorderColor:transparent;
$scrollPanelTrackBgColor:#f8f8f8;

//paginator
$paginatorPadding: 4px;
$paginatorElementWidth:1.8em;
$paginatorElementHeight:1.8em;
$paginatorBorder:1px solid #c8c8c8;
$paginatorBorderColor:#c8c8c8;
$paginatorElementHoverBgColor:#eaeaea;
$paginatorElementHoverIconColor:#333333;
$paginatorElementMargin:0;
$paginatorElementPadding:0;
$paginatorElementBorder:0 none;
$paginatorElementBorderColor:transparent;

//table
$tableHeaderCellPadding:6px 6px 3px;
$tableHeaderCellFontSize:14px;
$tableHeaderCellFontWeight:300;
$tableHeaderCellTextTransform:uppercase;
$tableHeaderCellBorder:none;
$tableBodyCellBorder:none;
$tableBodyCellPadding:5px 6px;
$tableFooterCellPadding:8px 6px;
$tableFooterCellFontWeight:300;
$tableFooterCellBorder:1px solid #c8c8c8;

//schedule
$scheduleEventBgColor:$primaryDarkColor;
$scheduleEventBorder:1px solid $primaryDarkColor;
$scheduleEventBorderColor:$primaryDarkColor;
$scheduleEventTextColor:$primaryTextColor;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:#ffffff;

//org chart
$organizationChartConnectorColor:#c8c8c8;

//messages box
$messagesFontSize:14px;
$messagesMargin:1em 0;
$messagesPadding:10px;
$messagesIconFontSize:20px;
$messageCloseIconFontSize:16px;


// single message outside of ui-messages
$messagePadding:5px 10px;
$messageMargin:20px 0;
$messageBorder:0 none;


//growl
$growlTopLocation:70px;
$growlIconFontSize:2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//overlays
$overlayContentBorder:1px solid #c8c8c8;

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1.5em;

//tooltip
$tooltipPadding:$inputPadding;

//steps
$stepsItemBorder:1px solid #c8c8c8;
$stepsItemWidth:28px;
$stepsItemHeight:28px;

//progressbar
$progressBarHeight:6px;
$progressBarTextValueHeight:24px;
$progressBarBorder:0 none;

//menu (e.g. menu, menubar, tieredmenu)
$menuBorder:0;
$menuPadding:0;
$menuitemPadding:10px 12px;
$menuitemMargin:0;
$submenuHeaderMargin: 0;
$submenuPadding: 1px 0;
$submenuitemHoverBgColor:#242430;

$overlayMenuBorder:1px solid #c8c8c8;
$overlayMenuShadow:0 0px 6px 0 rgba(0, 0, 0, 0.16);


//misc
$maskBgColor:rgba(0, 0, 0, 0.4);        //dialog mask
$inlineSpacing:.5em;                      //spacing between inline elements
$chipsItemMargin:0 .286em 0 0;            //autocomplete and chips token spacing
// $dataIconColor:$textSoftColor;       //icon color of a data such as treetoggler, table expander
// $dataIconHoverColor:$textColor;           //hover icon color of a data such as treetoggler, table expander

//general
$disabledOpacity:.5;                      //opacity of a disabled item


$containerMargin: 0 auto;
$containerWidth: 960px;
$containerPadding: 0 20px;
$containerLargeWidth: 1220px;
$containerLargePadding: 0 30px;

$cardWidthSmall: 400px;
$cardWidthMedium: 840px;
$cardWidthBlock: 1200px;