/**
 * this file will be imported dozens of times!
 * DO NOT define any css here!
 * only for overriding scss vars and scss mixins!
 */

$darkblue: #2E3A84;
$darkblue: #202a68;
$darkblue: #1a2460;
$darkblue: #1a2455;
$lightblue: #76BFEA;
$yellow: #FAE947;
$yellow: #e9d200;
$magenta: #C20D7A;


$primaryTextColor:#ffffff;
$primaryColor:$magenta;
$contrastColor:$lightblue;
$accentColor:$yellow;
$accentTextColor:$darkblue;
// $primaryColor:#008c9e;
$primaryDarkColor:$darkblue;
// $primaryDarkColor:#015a69;
// $primaryDarkColor:#45656c;
$primaryDarkerColor:$darkblue;
$secondaryColor:$lightblue;
// $greyColor:#4a6267;

$highlightColor:$yellow;
$highlightBgColor:$highlightColor;
$highlightTextColor:#000;

$datatableEvenRowBgColor:#fdfafc;
$datatableHoverBgColor:#e3e1e2;

@import 'global/variables';
@import 'global/_mixins';

// $buttonBgColor:transparent;
$buttonBorderRadius:25px;
$buttonBorderWidth:2px;
$buttonTextOnlyPadding:0.8em 1.4em;
$buttonWithLeftIconPadding:0.8em 1.0em;
$buttonWithRightIconPadding: 0.8em 1.0em;
$buttonHoverBgColor:#fff;
$buttonHoverTextColor:$primaryDarkColor;

// @import '//fonts.googleapis.com/css?family=Open+Sans:400';
$fontFamily:"Raleway","Helvetica Neue",Arial, sans-serif;
$headlinesFontFamily:"Raleway","Helvetica Neue",Arial, sans-serif;
// $fontFamily:"Muli","Helvetica Neue",Arial, sans-serif;
// $headlinesFontFamily:"Muli","Helvetica Neue",Arial, sans-serif;
$fontWeight:400;
$headlinesFontWeight:400;
//headers


// $sidebarTopStartBgColor:$secondaryColor;
// $sidebarTopEndBgColor:$secondaryColor;
// $sidebarTopStartBgColor:#00afab;
// $sidebarTopEndBgColor:#267871;
// $sidebarAnchorColor:#ffffff;
$menuitemActiveColor:rgb(36, 41, 40);
$rootMenuitemActiveIconColor:#ffffff;
$menuitemBadgeBgColor:$highlightColor;
$menuitemBadgeTextColor:#333333;


$panelHeaderBgColor:#ddd;
$panelHeaderTextColor:$textColor;
$panelHeaderBorder:0;
$panelHeaderBorderColor:transparent;
$panelFooterBorder:0;
$panelFooterBorderColor:transparent;
$panelFooterBgColor:#ddd;
$panelFooterTextColor:$textColor;

$panelHeaderAltBgColor: $primaryColor;
$panelHeaderAltTextColor: #fff;


$tableHeaderCellBgColor:$secondaryColor;
$tableHeaderCellTextColor:#fff;
$tableHeaderCellHoverBgColor:lighten($secondaryColor,5%);
$tableHeaderCellHoverTextColor:#fff;
$tableBodyRowEvenBgColor:#f0f0f0;
$tableBodyCellBorder:none;
$tableBodyCellBorderColor:transparent;
$tableHeaderCellBorder:none;
$tableHeaderCellBorderColor:transparent;

$paginatorBgColor:$secondaryColor;
$paginatorIconColor:#fff;
$paginatorActiveBgColor: #fff;
$paginatorActiveTextColor: $textColor;

$inputListMinWidth:240px;

$bottombarHeight:70px;
$bottombarMarginTop:0;

@mixin appExtrasLightTheme {
	--tabMenuitemBgColor: var(--primaryDarkerColor)!important;
	--toolbarMenuBgColor: var(--primaryDarkerColor)!important;
	--trackingConsentPositionBottom: 90px;
	--contrastTextColor: #000;

	.ui-button.ui-button-contrast {
		--buttonBgColor: var(--accentColor);
		--buttonBorderColor: var(--accentColor);
	}
}
