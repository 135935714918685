
// this file will be included once.
// use to define custom styles.

@import '../../../shared/variables';
@import 'global/theme_imports'; // found in libs/common/theme/frontend
@import 'global/theme_admin'; // include theme from libs/common/theme/frontend
@import '../../../shared/styles';


// color the toolbar above the sidebar dark, too -- looks weird without this
.layout-topbar:not(.mobile-sidebar-active).has-anchored-sidebar { border-left: calc(var(--sidebarWidth) - var(--topbarHeight)) solid var(--sidebarBgColor); }