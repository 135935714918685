

.p-text-center 				{ text-align:center; }
.p-text-right	 			{ text-align:right; }
.p-width-100,
.ui-width-100,
.ui-width-stretch			{ width:100%; display:block; }
.ui-flex1					{ flex:1; }

.ui-margin 					{ margin:20px 0; }
.ui-margin-small			{ margin:10px 0; }
.ui-margin-medium			{ margin:40px 0; }
.ui-margin-large			{ margin:60px 0; }
.ui-margin + .ui-margin		{ margin-top:0; }
.ui-margin:first-child 		{ margin-top:0; }
.ui-margin:last-child 		{ margin-bottom:0; }

.ui-float-right		 		{ float:right }

.ui-margin-remove	 		{ margin:0!important; }
.ui-margin-remove-top 		{ margin-top:0!important; }
.ui-margin-remove-bottom 	{ margin-bottom:0!important; }

.ui-margin-top 				{ margin-top:20px!important; }
.ui-margin-bottom 			{ margin-bottom:20px!important; }
.ui-margin-top-large		{ margin-top:60px!important; }
.ui-margin-bottom-large		{ margin-bottom:60px!important; }

.clearfix:after 			{ content:" "; display:block; clear:both; }
	
.ui-flex-center 			{ display:flex; align-items:center; justify-content:center; height:100%; }

.debug-btn {
	// & 						{ position:fixed; bottom:10px; right:10px; z-index:9999 }
	& 						{ border:none; background-color: #bb1b48; color: #fff; font-family: inherit; font-size: 12px; padding: 6px 9px; border-radius: 5px; }
	&:hover 				{ background-color: #c92a57; cursor:pointer; }
}



[class*="ui-align-"]		{ display:flex; }
.ui-align-left	 			{ justify-content: flex-start; }
.ui-align-center 			{ justify-content: center; }
.ui-align-right 			{ justify-content: flex-end; }
@media(min-width:$smallBreakpoint) {
	.ui-align-left-sm	 	{ justify-content: flex-start; }
	.ui-align-center-sm 	{ justify-content: center; }
	.ui-align-right-sm 		{ justify-content: flex-end; }
}
@media(min-width:$mobileBreakpoint) {
	.ui-align-left-md	 	{ justify-content: flex-start; }
	.ui-align-center-md 	{ justify-content: center; }
	.ui-align-right-md 		{ justify-content: flex-end; }
}
@media(min-width:$tabletBreakpoint) {
	.ui-align-left-lg	 	{ justify-content: flex-start; }
	.ui-align-center-lg 	{ justify-content: center; }
	.ui-align-right-lg 		{ justify-content: flex-end; }
}
@media(min-width:$largeBreakpoint) {
	.ui-align-left-xl	 	{ justify-content: flex-start; }
	.ui-align-center-xl 	{ justify-content: center; }
	.ui-align-right-xl 		{ justify-content: flex-end; }
}

[class*="ui-valign-"]		{ display:flex; }
.ui-valign-top	 			{ align-items: flex-start; }
.ui-valign-center 			{ align-items: center; }
.ui-valign-bottom 			{ align-items: flex-end; }
@media(min-width:$smallBreakpoint) {
	.ui-valign-top-sm	 	{ align-items: flex-start; }
	.ui-valign-center-sm 	{ align-items: center; }
	.ui-valign-bottom-sm 	{ align-items: flex-end; }
}
@media(min-width:$mobileBreakpoint) {
	.ui-valign-top-md	 	{ align-items: flex-start; }
	.ui-valign-center-md 	{ align-items: center; }
	.ui-valign-bottom-md 	{ align-items: flex-end; }
}
@media(min-width:$tabletBreakpoint) {
	.ui-valign-top-lg	 	{ align-items: flex-start; }
	.ui-valign-center-lg 	{ align-items: center; }
	.ui-valign-bottom-lg 	{ align-items: flex-end; }
}
@media(min-width:$largeBreakpoint) {
	.ui-valign-top-xl	 	{ align-items: flex-start; }
	.ui-valign-center-xl 	{ align-items: center; }
	.ui-valign-bottom-xl 	{ align-items: flex-end; }
}


.ui-text-left	 			{ text-align: left; }
.ui-text-center 			{ text-align: center; }
.ui-text-right 				{ text-align: right; }
@media(min-width:$smallBreakpoint) {
	.ui-text-left-sm	 	{ text-align: left; }
	.ui-text-center-sm 		{ text-align: center; }
	.ui-text-right-sm 		{ text-align: right; }
}
@media(min-width:$mobileBreakpoint) {
	.ui-text-left-md	 	{ text-align: left; }
	.ui-text-center-md 		{ text-align: center; }
	.ui-text-right-md 		{ text-align: right; }
}
@media(min-width:$tabletBreakpoint) {
	.ui-text-left-lg	 	{ text-align: left; }
	.ui-text-center-lg 		{ text-align: center; }
	.ui-text-right-lg 		{ text-align: right; }
}
@media(min-width:$largeBreakpoint) {
	.ui-text-left-xl	 	{ text-align: left; }
	.ui-text-center-xl 		{ text-align: center; }
	.ui-text-right-xl 		{ text-align: right; }
}
	


//
// hide sm + md will hide everything BELOW breakpoint, hide lg + will hide ONLY ABOVE breakpoint!
// show sm + md will show everything BELOW breakpoint, show lg + will show ONLY ABOVE breakpoint!
// 
	
.ui-hidden						{ display:none; }
.ui-hidden-sm {
	@media(max-width:$smallBreakpoint - 1)	{ display:none; }
}
.ui-hidden-md {
	@media(max-width:$mobileBreakpoint - 1)	{ display:none; }
}
.ui-hidden-lg {
	@media(min-width:$tabletBreakpoint)		{ display:none; }
}
.ui-hidden-xl {
	@media(min-width:$largeBreakpoint)		{ display:none; }
}
.ui-hidden-xxl {
	@media(min-width:$xlargeBreakpoint)		{ display:none; }
}

.ui-visible						{ display:block; }
.ui-visible-sm {
	@media(max-width:$smallBreakpoint - 1) 	{ display:block; }
	@media(min-width:$smallBreakpoint) 		{ display:none; }
}
.ui-visible-md {
	@media(max-width:$mobileBreakpoint - 1) 	{ display:block; }
	@media(min-width:$mobileBreakpoint) 		{ display:none; }
}
.ui-visible-lg {
	@media(max-width:$tabletBreakpoint - 1) 	{ display:none; }
	@media(min-width:$tabletBreakpoint) 		{ display:block; }
}
.ui-visible-xl {
	@media(max-width:$largeBreakpoint - 1) 	{ display:none; }
	@media(min-width:$largeBreakpoint) 		{ display:block; }
}
.ui-visible-xxl {
	@media(max-width:$xlargeBreakpoint - 1) 	{ display:none; }
	@media(min-width:$xlargeBreakpoint) 		{ display:block; }
}
