
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
@mixin autofill {
	&:-webkit-autofill,
	&:-internal-autofill-selected {
		&,&:hover,&:active,&:focus {
			@content;
		}
	}
}

@mixin flex-center-safe() {
	// https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
	display:flex; 
	// align-items:center; 
	// justify-content:center; 
	// overflow:auto;
	// margin auto destroys safe centering
	// margin:auto;
	// safe centering ensures correct overflowing, only supported by FF as of 2020
	// align-items:safe center; 
	// justify-content:safe center; 

	// this will enable overflow scrolling in Chrome, Opera and Safari
	min-height: -moz-min-content; 
	min-height: -webkit-min-content; 
	min-height: min-content;
}

@mixin appearance($val) {
    -webkit-appearance: $val;
    -moz-appearance: $val;
    appearance: $val;
}

@mixin focused-input() {
    border-color: var(--inputFocusBorderColor);
    outline: 0 none;
    box-shadow:$inputFocusShadow;
}

@mixin disabled-input() {
	border-color: var(--inputDisabledBorderColor);
    background-color: var(--inputDisabledBgColor);
    color: var(--inputDisabledTextColor);
    cursor: default !important;
    pointer-events: none;
}

// these mixins are intended to be overriden from app-specific app-variables.scss files.

@mixin appExtrasLightTheme() {}
@mixin appExtrasDarkTheme() {}

@mixin platformExtrasLightTheme() {}
@mixin platformExtrasDarkTheme() {}