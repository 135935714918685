
//anchors

@mixin lightTheme {
	& {
		// neutral base colors
		--contentColor: #{$contentColor};
		--contentSoftColor: #{$contentSoftColor};
		--backgroundColor: #{$backgroundColor};
		--backgroundSoftColor: #{$backgroundSoftColor};
		--softGreyColor: #{$softGreyColor};
		--greyColor: #{$greyColor};
		--deepGreyColor: #{$deepGreyColor};
		--inputBgColor: #ffffffad;

		// colorful colors
		--primaryColor: #{$primaryColor};
		--primaryTextColor: #{$primaryTextColor};
		--primaryLightenedColor: #{lighten($primaryColor, 15%)};
		--primaryDarkenedColor: #{darken($primaryColor, 10%)};
		// TODO: find better name for primaryDark color, it has nothing todo with primary. Secondary / tertiary?
		--primaryDarkColor: #{$primaryDarkColor};
		--primaryDarkerColor: #{$primaryDarkerColor};
		--secondaryColor: #{$secondaryColor};
		--secondaryTextColor: var(--primaryTextColor);
		--secondaryLightenedColor: #{lighten($secondaryColor, 15%)};
		--contrastColor: #{$contrastColor};
		--contrastTextColor: var(--primaryTextColor);
		--contrastLightenedColor: #{lighten($contrastColor, 15%)};
		--accentColor: #{$accentColor};
		--accentTextColor: #{$accentTextColor};
		--logoBgColor: var(--primaryColor);
		
		// text colors
		--textColor: #{$textColor};
		--mainTextColor: var(--textColor);
		--textSoftColor: #{$textSoftColor};
		--invertedTextColor: #{$invertedTextColor};
		--linkColor: var(--primaryColor);
		--linkHoverColor: var(--primaryDarkColor);
		--linkActiveColor: var(--primaryDarkerColor);

		--disabledBgColor: #{$disabledBgColor};
		--disabledTextColor: #{$disabledTextColor};
		--disabledBorderColor: #{$disabledBorderColor};

		--highlightColor: #{$highlightColor};
		--highlightDarkenedColor: var(--primaryDarkenedColor);
		--highlightBgColor: #{$highlightColor};
		--highlightTextColor: #{$highlightTextColor};

		--overlaySoftColor: rgba(0,0,0,.1);
		--overlayDarkenColor: rgba(0, 0, 0, 0.15);
		--invertedOverlaySoftColor: rgba(255, 255, 255, 0.5);

		--infoColor: #007ad9;
		--infoLightenedColor: #8ac3f1;
		--infoTextColor: #000;
		--successColor: #60b924;
		--successLightenedColor: #7bcc46;
		--successFadedColor: rgba(124, 204, 70, 0.5);
		--successTextColor: #000;
		--warningColor: #cf9800;
		--warningLightenedColor: #e4b536;
		--warningFadedColor: rgba(207, 152, 0, 0.65);
		--warningTextColor: #000;
		--dangerColor: #b12140;
		--dangerLightenedColor: #d44060;		
		--dangerFadedColor: rgba(177, 33, 64, 0.5);
		--dangerTextColor: #000;

		--maintenanceWarningColor: #ffb818;

		--validationErrorBgColor: #{$validationMessageBgColor};
		--validationErrorTextColor: #{$validationMessageTextColor};


		--fontFamily: #{$fontFamily};
		--fontWeight: #{$fontWeight};
		--fontSize: #{$fontSize};
		--fontSizeSmall: #{$fontSizeSmall};
		--lineHeight: #{$lineHeight};
		--headlinesFontFamily: #{$headlinesFontFamily};
		--headlinesFontWeight: #{$headlinesFontWeight};
		--headlinesTextColor: #{$headlinesTextColor};
		--headlinesLineHeight: #{$headlinesLineHeight};

		--dividerColor: #{$dividerColor};

		--borderRadius: #{$borderRadius};

		--scrollbarColor: #888;
		--scrollbarBgColor: #ccc;

		--sidebarTopStartBgColor: var(--secondaryColor);
		--sidebarTopEndBgColor: var(--secondaryColor);
		--sidebarAnchorColor: var(--textColor);

		--horizontalLayoutTopbarTextColor: var(--contentColor);
		--horizontalLayoutTopbarTextHoverColor: #ACDAEB;

		--topbarBgColor: var(--contentColor);
		--topbarInputBorderColor: var(--backgroundColor);
		--topbarSubmenuHoverBgColor: var(--contentSoftColor);
		--topbarMobileMenuBgColor: var(--contentColor);
		--topbarIconColor: #b6b7c2;
		--topbarHoverIconColor: #7f8087;
		--topbarActiveIconColor: #48494d;

		--toolbarBgColor: var(--backgroundColor);
		--toolbarBorderColor: var(--toolbarBgColor);
		--toolbarMenuBgColor: var(--secondaryColor);
		--toolbarMenuitemActiveBgColor: var(--primaryColor);
		--toolbarMenuitemActiveColor: var(--primaryTextColor);

		--topbarHeight: #{$topbarHeight};
		--bottombarHeight: #{$bottombarHeight};

		--trackingConsentPositionBottom: 20px;

		--sidebarWidth: #{$sidebarWidth};
		--sidebarCollapsedWidth: #{$sidebarCollapsedWidth};
		--sidebarBgColor: var(--contentColor);
		--sidebarShadowColor: rgba(0,0,0,.25);
		--sidebarMenuitemTextColor: var(--textColor);
		--sidebarMenuitemIconBgColor:transparent;
		--sidebarMenuitemIconColor:var(--textColor);
		--sidebarMenuitemHoverTextColor:var(--highlightTextColor);
		--sidebarMenuitemHoverIconColor:var(--highlightTextColor);
		--sidebarMenuitemHoverIconBgColor:transparent;
		--sidebarMenuitemHoverBgColor:var(--highlightBgColor);
		--sidebarMenuitemBorderColor:transparent;
		--sidebarMenuitemActiveTextColor:var(--highlightTextColor);
		--sidebarMenuitemActiveIconColor:var(--highlightTextColor);
		--sidebarMenuitemActiveIconBgColor:transparent;
		--sidebarMenuitemActiveBgColor:var(--highlightColor);
		--sidebarSubMenuitemBgColor:var(--contentSoftColor);
		--sidebarSubMenuitemIconBgColor:transparent;
		--sidebarSubMenuitemIconColor:var(--textColor);
		--sidebarSubMenuitemHoverBgColor:var(--highlightBgColor);
		--sidebarSubMenuitemHoverTextColor:var(--highlightTextColor);
		--sidebarSubMenuitemActiveTextColor:var(--highlightColor);
		--sidebarSubMenuitemActiveIconColor:var(--highlightColor);
		--sidebarSubMenuitemActiveIconBgColor:transparent;
		--sidebarSubMenuitemActiveBgColor:var(--sidebarBgColor);
		--sidebarMenuitemBadgeBgColor:$highlightBgColor;
		--sidebarMenuitemBadgeTextColor:#333;

		--submenuBgColor: #fff;
		--contentMobileMaskBgColor: rgba(0,0,0,.3);

		--rightPanelBgColor: #{$rightPanelBgColor};

		--adminContentBorderColor: #{$adminContentBorderColor};



		// /* ------------------------------------------------------------------------ */

		--inputHeight: 40px;
		--inputWithPlaceholderHeight: 50px;
		--inputWithPlaceholderPaddingTop: 1.2em;
		--inputPadding: 10px;
		--inputBorderColor: #{$inputBorderColor};
		--inputBorderRadius: #{$inputBorderRadius};
		--inputHoverBorderColor: #{$inputHoverBorderColor};
		--inputHoverBgColor: var(--inputBgColor);
		--inputErrorBorderColor: #{$inputErrorBorderColor};
		--inputPlaceholderTextColor: #{$inputPlaceholderTextColor};
		--inputDisabledBgColor: #{$inputDisabledBgColor};
		--inputDisabledTextColor: #{$inputDisabledTextColor};
		--inputDisabledBorderColor: #{$inputDisabledBorderColor};
		--inputFocusBorderColor: #{$inputFocusBorderColor};

		--inputAutofillTextColor: var(--textColor);
		--inputAutofillBgColor: #afb8e2;
				
		--inputGroupAddonBorderColor: var(--disabledBorderColor);
		--inputGroupAddonBgColor: var(--disabledBgColor);
		--inputGroupAddonTextColor: var(--textColor);

		--inputListItemBgColor: var(--inputBgColor);
		--inputListItemBorderColor: #{$inputListItemBorderColor};
		--inputListItemHighlightBgColor: #{$inputListItemHighlightBgColor};
		--inputListItemHighlightTextColor: #{$inputListItemHighlightTextColor};
		--inputListItemDividerColor: #{$inputListItemDividerColor};

		--inputListBgColor:var(--contentSoftColor);
		--inputListItemTextColor:var(--textColor);
		--inputListItemHoverBgColor:var(--softGreyColor);
		--inputListItemHoverTextColor:var(--textColor);
		--inputListGroupLabelBgColor:var(--secondaryColor);
		--inputListGroupLabelTextColor:var(--secondaryTextColor);
		
		--inputListItemIconColor:var(--textColor);
		--inputListDragHandleBgColor: #{$inputListDragHandleBgColor};

		--inputListHeaderBorder: #{$inputListHeaderBorder};
		--inputListHeaderSearchIconColor: #{$inputListHeaderSearchIconColor};
		--inputListHeaderCloseIconColor: var(--textColor);
		--inputListHeaderCloseIconHoverColor: #{$inputListHeaderCloseIconHoverColor};
		--inputListHeaderCloseIconTransition: #{$inputListHeaderCloseIconTransition};
		--inputListHeaderBgColor:var(--contentColor);
		--inputListHeaderTextColor:var(--textColor);
		--inputListHeaderBorderColor:var(--contentColor);

		--inputContentPanelBgColor: #{$inputContentPanelBgColor};
		--inputContentPanelTextColor: #{$inputContentPanelTextColor};

		--orderListItemBorderColor: #{$orderListItemBorderColor};

		--inputOverlayBorderColor: #{$inputOverlayBorderColor};

		--inputDropdownIconColor: #{$inputDropdownIconColor};

		--buttonBorderWidth: #{$buttonBorderWidth};
		--buttonOutlineBorderWidth: 2px; /* outline button MUST have a border width, thus a separate var is used! */
		--buttonBorderRadius: #{$buttonBorderRadius};
		--buttonBgColor: var(--primaryColor);
		--buttonBorderColor: var(--buttonBgColor);
		--buttonTextColor: var(--primaryTextColor);
		--buttonIconColor: var(--buttonTextColor);
		--buttonHoverBgColor: var(--primaryDarkColor);
		--buttonHoverBorderColor: var(--buttonHoverBgColor);
		--buttonHoverTextColor: var(--primaryTextColor);
		--buttonHoverIconColor: var(--buttonHoverTextColor);
		--buttonActiveBgColor: var(--primaryDarkerColor);
		--buttonActiveTextColor: var(--primaryTextColor);
		--buttonActiveIconColor: var(--buttonActiveTextColor);
		--buttonActiveBorderColor: var(--primaryDarkColor);
		--buttonFocusBorderColor: var(--primaryDarkColor);

		--roundedButtonBorderRadius: #{$roundedButtonBorderRadius};

		.ui-button.ui-button-outline  {
			--buttonBgColor: transparent;
			--buttonBorderColor: var(--softGreyColor);
			--buttonIconColor: var(--textColor);
			--buttonTextColor: var(--textColor);
			--buttonHoverBgColor: var(--invertedOverlaySoftColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--textColor);
			--buttonHoverIconColor: var(--textColor);
			--buttonActiveBgColor:var(--invertedOverlaySoftColor);
			--buttonActiveTextColor: var(--textColor);
			--buttonActiveBorderColor: var(--textColor);
		}

		.ui-button.ui-button-invisible  {
			--buttonBgColor: transparent;
			--buttonBorderColor: transparent;
			--buttonIconColor: var(--textColor);
			--buttonTextColor: var(--textColor);
			--buttonHoverBgColor: transparent;
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--textColor);
			--buttonHoverIconColor: var(--textColor);
			--buttonActiveBgColor: transparent;
			--buttonActiveTextColor: var(--textColor);
			--buttonActiveBorderColor: transparent;
		}

		.ui-button.ui-button-secondary {
			--buttonBgColor:var(--softGreyColor);
			--buttonBorderColor: #b1b1b1;
			--buttonIconColor:var(--textColor);
			--buttonTextColor: var(--textColor);
			--buttonHoverBgColor: var(--backgroundColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--textColor);
			--buttonHoverIconColor: var(--textColor);
			--buttonActiveBgColor:var(--buttonHoverBgColor);
			--buttonActiveTextColor: var(--invertedTextColor);
			--buttonActiveBorderColor: var(--softGreyColor);
		}
		
		.ui-button.ui-button-contrast {
			--buttonBgColor: var(--contrastColor);
			--buttonBorderColor: var(--buttonBgColor);
			--buttonTextColor: var(--contrastTextColor);
			--buttonIconColor: var(--contrastTextColor);
			--buttonHoverBgColor: var(--contrastLightenedColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--contrastTextColor);
			--buttonHoverIconColor: var(--contrastTextColor);
			--buttonActiveBgColor: var(--buttonHoverBgColor);
			--buttonActiveBorderColor: var(--contrastLightenedColor);
			--buttonActiveTextColor: var(--contrastTextColor);
		}
		
		.ui-button.ui-button-info {
			--buttonBgColor: var(--infoColor);
			--buttonBorderColor: var(--buttonBgColor);
			--buttonTextColor: var(--primaryTextColor);
			--buttonIconColor: var(--primaryTextColor);
			--buttonHoverBgColor: var(--infoLightenedColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--primaryTextColor);
			--buttonHoverIconColor: var(--primaryTextColor);
			--buttonActiveBgColor: var(--infoLightenedColor);
			--buttonActiveBorderColor: var(--infoLightenedColor);
			--buttonActiveTextColor: var(--infoTextColor);
		}
		
		.ui-button.ui-button-success {
			--buttonBgColor: var(--successColor);
			--buttonBorderColor: var(--buttonBgColor);
			--buttonTextColor: var(--primaryTextColor);
			--buttonIconColor: var(--primaryTextColor);
			--buttonHoverBgColor: var(--successLightenedColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--primaryTextColor);
			--buttonHoverIconColor: var(--primaryTextColor);
			--buttonActiveBgColor: var(--successLightenedColor);
			--buttonActiveBorderColor: var(--successLightenedColor);
			--buttonActiveTextColor: var(--successTextColor);
		}
		
		.ui-button.ui-button-warning {
			--buttonBgColor: var(--warningColor);
			--buttonBorderColor: var(--buttonBgColor);
			--buttonTextColor: var(--primaryTextColor);
			--buttonIconColor: var(--primaryTextColor);
			--buttonHoverBgColor: var(--warningLightenedColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--primaryTextColor);
			--buttonHoverIconColor: var(--primaryTextColor);
			--buttonActiveBgColor: var(--warningLightenedColor);
			--buttonActiveBorderColor: var(--warningLightenedColor);
			--buttonActiveTextColor: var(--warningTextColor);
		}
		
		.ui-button.ui-button-danger {
			--buttonBgColor: var(--dangerColor);
			--buttonBorderColor: var(--buttonBgColor);
			--buttonTextColor: var(--primaryTextColor);
			--buttonIconColor: var(--primaryTextColor);
			--buttonHoverBgColor: var(--dangerLightenedColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--primaryTextColor);
			--buttonHoverIconColor: var(--primaryTextColor);
			--buttonActiveBgColor: var(--dangerLightenedColor);
			--buttonActiveBorderColor: var(--dangerLightenedColor);
			--buttonActiveTextColor: var(--dangerTextColor);
		}
		
		.ui-button.ui-button-disabled {
			--buttonBgColor: var(--disabledBgColor);
			--buttonBorderColor: var(--inputDisabledBorderColor);
			--buttonTextColor: var(--disabledTextColor);
			--buttonIconColor: var(--disabledTextColor);
			--buttonHoverBgColor: var(--inputDisabledBgColor);
			--buttonHoverBorderColor: var(--buttonHoverBgColor);
			--buttonHoverTextColor: var(--inputDisabledTextColor);
			--buttonHoverIconColor: var(--inputDisabledTextColor);
			--buttonActiveBgColor: var(--inputDisabledBgColor);
			--buttonActiveBorderColor: var(--inputDisabledBorderColor);
			--buttonActiveTextColor: var(--inputDisabledTextColor);
		}



		// //input-checkbox
		--checkboxBgColor: var(--inputBgColor);
		--checkboxBorderColor: var(--inputBorderColor);
		--checkboxHoverBgColor: var(--inputHoverBgColor);
		--checkboxHoverBorderColor: var(--inputHoverBorderColor);
		--checkboxActiveBorderColor: var(--primaryColor);
		--checkboxActiveBgColor: var(--primaryColor);
		--checkboxActiveTextColor: var(--primaryTextColor);
		--checkboxActiveHoverBgColor: var(--primaryColor);
		--checkboxActiveHoverTextColor: var(--primaryTextColor);
		--checkboxActiveHoverBorderColor: var(--primaryColor);
		--checkboxActiveFocusBgColor: var(--primaryColor);
		--checkboxActiveFocusTextColor: var(--primaryTextColor);
		--checkboxActiveFocusBorderColor: var(--primaryColor);
		--checkboxFocusBgColor: var(--inputBgColor);
		--checkboxFocusTextColor: var(--primaryColor);
		--checkboxFocusBorderColor: var(--primaryColor);


		// //radiobutton
		--radiobuttonBgColor: var(--inputBgColor);
		--radiobuttonBorderColor: var(--inputBorderColor);
		--radiobuttonActiveBorderColor: var(--primaryColor);
		--radiobuttonActiveBgColor: var(--primaryColor);
		--radiobuttonActiveTextColor: var(--primaryTextColor);
		--radiobuttonActiveHoverBgColor: var(--primaryColor);
		--radiobuttonActiveHoverTextColor: var(--primaryTextColor);
		--radiobuttonActiveHoverBorderColor: var(--primaryColor);
		--radiobuttonActiveFocusBgColor: var(--primaryColor);
		--radiobuttonActiveFocusTextColor: var(--primaryTextColor);
		--radiobuttonActiveFocusBorderColor: var(--primaryColor);
		--radiobuttonFocusBgColor: var(--inputBgColor);
		--radiobuttonFocusTextColor: var(--primaryColor);
		--radiobuttonFocusBorderColor: var(--primaryColor);

		// //inplace
		--inplaceHoverBgColor: #{$inplaceHoverBgColor};
		--inplaceHoverTextColor: #{$inplaceHoverTextColor};

		// //input switch
		--inputSwitchHoverBorderColor: var(--primaryColor);

		--inputSwitchOffBgColor: var(--inputBgColor);
		--inputSwitchOffBorderColor: var(--softGreyColor);
		--inputSwitchOffHandleBgColor: var(--softGreyColor);
		--inputSwitchOffHandleHoverBgColor: var(--softGreyColor);
		--inputSwitchOffHandleFocusBgColor: var(--softGreyColor);

		--inputSwitchOnBgColor: var(--inputBgColor);
		--inputSwitchOnBorderColor: var(--primaryColor);
		--inputSwitchOnHandleBgColor: var(--primaryColor);
		--inputSwitchOnHandleFocusBgColor: var(--primaryColor);
		--inputSwitchOnHandleHoverBgColor: var(--primaryColor);





		// //container common
		--containerHeaderBgColor: var(--primaryColor);
		--containerHeaderBorderColor: var(--primaryColor);
		--containerHeaderTextColor: var(--primaryTextColor);
		--containerHeaderIconColor: var(--primaryTextColor);
		--containerHeaderIconHoverColor: var(--primaryTextColor);

		--containerHeaderHoverBgColor: var(--primaryDarkColor);
		--containerHeaderHoverBorderColor: var(--primaryDarkColor);
		--containerHeaderHoverTextColor: var(--primaryTextColor);
		--containerHeaderHoverIconColor: var(--primaryTextColor);

		--containerHeaderActiveBgColor: var(--highlightBgColor);
		--containerHeaderActiveBorderColor: var(--highlightBgColor);
		--containerHeaderActiveTextColor: var(--highlightTextColor);
		--containerHeaderActiveIconColor: var(--highlightTextColor);

		--containerHeaderActiveHoverBgColor: var(--highlightDarkenedColor);
		--containerHeaderActiveHoverBorderColor: var(--highlightDarkenedColor);
		--containerHeaderActiveHoverTextColor:var(--highlightTextColor);
		--containerHeaderActiveHoverIconColor: var(--highlightTextColor);


		// //panel common (e.g. accordion, panel, tabview)
		--panelHeaderBgColor:var(--backgroundColor);
		--panelHeaderTextColor:var(--textColor);
		--panelHeaderIconColor: var(--panelHeaderTextColor);
		--panelHeaderBorderColor:var(--contentColor);
		--panelContentBgColor:var(--contentColor);
		--panelContentTextColor:var(--textColor);
		--panelContentBorderColor:var(--contentColor);
		--panelFooterBgColor:var(--backgroundColor);
		--panelFooterTextColor:var(--textColor);
		--panelFooterBorderColor:var(--contentColor);
		--panelNestedContentBgColor:var(--contentSoftColor);
		
		--panelHeaderAltBgColor: var(--primaryColor);;
		--panelHeaderAltTextColor: var(--primaryTextColor);
		--panelHeaderHoverBgColor: var(--containerHeaderHoverBgColor);
		--panelHeaderHoverBorderColor: var(--containerHeaderHoverBorderColor);
		--panelHeaderHoverTextColor: var(--containerHeaderHoverTextColor);
		--panelHeaderIconHoverColor: var(--panelHeaderHoverTextColor);
		--panelHeaderHoverIconColor: var(--panelHeaderHoverTextColor);
		--panelHeaderActiveBgColor: var(--containerHeaderActiveBgColor);
		--panelHeaderActiveBorderColor: var(--containerHeaderActiveBorderColor);
		--panelHeaderActiveTextColor: var(--containerHeaderActiveTextColor);
		--panelHeaderActiveIconColor: var(--containerHeaderActiveIconColor);
		--panelHeaderActiveHoverBgColor: var(--containerHeaderActiveHoverBgColor);
		--panelHeaderActiveHoverBorderColor: var(--containerHeaderActiveHoverBorderColor);
		--panelHeaderActiveHoverTextColor: var(--containerHeaderActiveHoverTextColor);
		--panelHeaderActiveHoverIconColor: var(--containerHeaderActiveHoverIconColor);

		--accordionContentBgColor: var(--contentSoftColor);
		--accordionContentPadding: 0.571em 1em;

		// tabMenu
		--tabMenuBgColor: transparent;
		--tabMenuitemBgColor: var(--secondaryColor);
		--tabMenuBorderColor:var(--tabMenuitemBgColor);
		--tabMenuitemTextColor: var(--primaryTextColor);
		--tabMenuitemActiveBgColor: var(--primaryColor);
		--tabMenuitemActiveTextColor: var(--primaryTextColor);
		--tabMenuIconColor:var(--tabMenuitemTextColor);
		--tabMenuHoverBgColor:var(--primaryColor);
		--tabMenuHoverBorderColor:var(--primaryColor);
		--tabMenuHoverTextColor:var(--primaryTextColor);
		--tabMenuHoverIconColor:var(--tabMenuHoverTextColor);
		--tabMenuActiveBgColor:var(--primaryColor);
		--tabMenuActiveBorderColor:var(--primaryColor);
		--tabMenuActiveTextColor:var(--primaryTextColor);
		--tabMenuActiveIconColor:var(--tabMenuActiveIconColor);

		// //tabview
		--tabViewBgColor: transparent;
		--tabViewTextColor: var(--textColor);
		--tabViewBorderColor: var(--deepGreyColor);
		--tabViewHoverBgColor: transparent;
		--tabViewHoverTextColor: var(--secondaryColor);
		--tabViewHoverBorderColor: var(--secondaryColor);
		--tabViewActiveBgColor: transparent;
		--tabViewActiveTextColor: var(--primaryColor);
		--tabViewActiveBorderColor: var(--primaryColor);
		--tabViewPanelColor: transparent;
		--tabViewPanelSeparationBorderWidth: 2px;

		// tab header
		--tabHeaderBorderColor: #c8c8c8;
		--tabHeaderBgColor:#dadada;
		--tabHeaderTextColor:#000;
		--tabHeaderIconColor: var(--containerHeaderIconColor);
		--tabHeaderIconHoverColor: var(--containerHeaderIconHoverColor);
		--tabHeaderHoverBgColor: var(--containerHeaderHoverBgColor);
		--tabHeaderHoverBorderColor: var(--containerHeaderHoverBorderColor);
		--tabHeaderHoverTextColor: var(--containerHeaderHoverTextColor);
		--tabHeaderHoverIconColor: var(--containerHeaderHoverIconColor);
		--tabHeaderActiveBgColor: var(--containerHeaderActiveBgColor);
		--tabHeaderActiveBorderColor: var(--containerHeaderActiveBorderColor);
		--tabHeaderActiveTextColor: var(--containerHeaderActiveTextColor);
		--tabHeaderActiveIconColor: var(--containerHeaderActiveIconColor);
		--tabHeaderActiveHoverBgColor: var(--containerHeaderActiveHoverBgColor);
		--tabHeaderActiveHoverBorderColor: var(--containerHeaderActiveHoverBorderColor);
		--tabHeaderActiveHoverTextColor: var(--containerHeaderActiveHoverTextColor);
		--tabHeaderActiveHoverIconColor: var(--containerHeaderActiveHoverIconColor);

		// //scrollpanel
		--scrollPanelHandleBgColor: #{$scrollPanelHandleBgColor};
		--scrollPanelTrackBgColor: #{$scrollPanelTrackBgColor};
		--scrollPanelTrackBorderColor: #{$scrollPanelTrackBorderColor};

		// //paginator
		--paginatorBgColor: transparent;
		--paginatorTextColor: var(--textColor);
		--paginatorIconColor: var(--paginatorTextColor);
		--paginatorBorderColor: transparent;
		--paginatorElementHoverBgColor: #{$paginatorElementHoverBgColor};
		--paginatorElementHoverIconColor: #{$paginatorElementHoverIconColor};
		--paginatorElementBorderColor: #{$paginatorElementBorderColor};
		--paginatorActiveBgColor: var(--primaryColor);
		--paginatorActiveTextColor: var(--primaryTextColor);



		// //table
		--tableHeaderCellBgColor: var(--contentColor); // may be sticky so should not be transparent ever!
		--tableHeaderCellTextColor: var(--textColor);
		--tableHeaderCellBorderColor: var(--secondaryColor);
		--tableHeaderCellHoverBgColor: var(--secondaryColor);
		--tableHeaderCellHoverTextColor: var(--secondaryTextColor);
		--tableBodyRowBgColor: #eee;
		--tableBodyRowEvenBgColor: #fafafa;
		--tableBodyRowTextColor: var(--textColor);
		--tableBodyRowHoverBgColor: var(--softGreyColor);
		--tableBodyRowHoverTextColor: var(--textColor);
		--tableBodyClickableRowHoverBgColor: var(--primaryColor);
		--tableBodyClickableRowHoverTextColor: var(--primaryTextColor);
		--tableBodyCellBorderColor: transparent;

		--tableFooterCellBgColor: var(--secondaryColor);
		--tableFooterCellTextColor: var(--secondaryTextColor);
		--tableFooterCellBorderColor: var(--secondaryColor);
		--tableResizerHelperBgColor: var(--primaryColor);

		--tableGridHoverBgColor:var(--tableBodyRowHoverBgColor);
		--tableGridHoverTextColor:var(--tableBodyRowHoverTextColor);

		// //schedule
		--scheduleEventBgColor: #{$scheduleEventBgColor};
		--scheduleEventBorderColor: #{$scheduleEventBorderColor};
		--scheduleEventTextColor: #{$scheduleEventTextColor};


		// //lightbox
		--lightBoxNavIconColor: #{$lightBoxNavIconColor};


		// //org chart
		// --organizationChartConnectorColor: #{$organizationChartConnectorColor};


		// //messages box
		--infoMessageBgColor: var(--infoLightenedColor);
		--infoMessageBorderColor: var(--infoColor);
		--infoMessageTextColor: var(--infoTextColor);
		--infoMessageIconColor: var(--infoTextColor);

		--successMessageBgColor: var(--successLightenedColor);
		--successMessageBorderColor: var(--successColor);
		--successMessageTextColor: var(--successTextColor);
		--successMessageIconColor: var(--successTextColor);

		--warnMessageBgColor: var(--warningLightenedColor);
		--warnMessageBorderColor: var(--warningColor);
		--warnMessageTextColor: var(--warningTextColor);
		--warnMessageIconColor: var(--warningTextColor);

		--errorMessageBgColor: var(--dangerLightenedColor);
		--errorMessageBorderColor: var(--dangerColor);
		--errorMessageTextColor: var(--dangerTextColor);
		--errorMessageIconColor: var(--dangerTextColor);

		--validationMessageBgColor: var(--validationErrorBgColor);
		--validationMessageTextColor: var(--validationErrorTextColor);


		// //overlays
		--overlayContentBorderColor: var(--panelContentBorderColor);


		// //overlay panel
		--overlayPanelCloseIconBgColor: var(--primaryColor);
		--overlayPanelCloseIconColor: var(--primaryTextColor);
		--overlayPanelCloseIconHoverBgColor: var(--primaryDarkerColor);
		--overlayPanelCloseIconHoverColor: var(--primaryTextColor);


		// //tooltip
		--tooltipBgColor: var(--primaryDarkColor);
		--tooltipTextColor: var(--primaryTextColor);


		// //steps
		--stepsItemBgColor: var(--mainColor);
		--stepsItemBorderColor: var(--softGreyColor);
		--stepsItemNumberColor: var(--textColor);
		--stepsItemTextColor: var(--softGreyColor);


		// //progressbar
		--progressBarBorderColor: transparent;
		--progressBarBgColor: rgba(220,220,220,.2);
		--progressBarValueBgColor: var(--primaryColor);


		// //menu (e.g. menu, menubar, tieredmenu)
		--menuBgColor: transparent;
		--menuBorderColor: transparent;
		--menuTextColor: var(--textColor);
		--menuitemTextColor: var(--textColor);
		--menuitemIconBgColor: var(--backgroundColor);
		--menuitemIconColor: var(--primaryColor);

		--menuitemHoverTextColor: var(--highlightTextColor);
		--menuitemHoverIconColor: var(--highlightTextColor);
		--menuitemHoverBgColor: var(--highlightBgColor);

		--menuitemActiveTextColor: var(--highlightTextColor);
		--menuitemActiveIconColor: var(--highlightTextColor);
		--menuitemActiveBgColor: var(--highlightBgColor);

		// used for dark admin sidebar
		--submenuitemHoverBgColor: #242430;

		--overlayMenuBgColor:var(--contentColor);
		--overlayMenuBorderColor:var(--backgroundSoftColor);


		// //misc
		--maskBgColor: #{$maskBgColor};
		// --dataIconColor: #{$dataIconColor};
		// --dataIconHoverColor: #{$dataIconHoverColor};


		// //general

		--datatableEvenRowBgColor: #{$datatableEvenRowBgColor};
		--datatableHoverBgColor: #{$datatableHoverBgColor};
		// --stateHighlightBgColor: rgb(121, 129, 138);
		// --stateHoverBgColor: #{$stateHoverBgColor};
		// --stateHoverTextColor: #{$stateHoverTextColor};
		--rowgroupHeaderTextColor: #ccc;

		--dragPlaceholderBgColor: rgb(121, 129, 138);

		--boxHoverShadowColor: rgba(0,0,0, 0.27);
		--inputOverlayShadow:0 3px 8px 0 rgba(0, 0, 0, .25);
		--dialogShadow:0 0 15px 5px rgb(0, 0, 0, .28);


		--jsonTreeNeutralText:  #e9e9e9;
		--jsonTreeTypeString:  #ffdf50;
		--jsonTreeTypeNumber:  #f65050;
		--jsonTreeTypeBoolean:  #f65050;
		--jsonTreeTypeNull:  #2458a7;
		--jsonTreeTypeArray:  #3ae2ff;
		--jsonTreeTypeClass:  #00e89a;
		--jsonTreeTypeInstance:  #a3eb42;
		--jsonTreeTypeObject:  #eeeeee;
		--jsonTreeTypeFunction:  #a1ff5c;
		--jsonTreeTypeUndefined:  #e66a8c;
		--jsonTreeTypeInfo:  #10d4ff;
		--jsonTreeToggler:  #999;
		// --jsonTreeKey:  #e4cbe1;
		--jsonTreeKey:  #b396ff;
		--jsonTreePreviewKey:  #8d94a5;
		--jsonTreeBracket:  #e66a8c;
		--jsonTreeSeparator:  var(--jsonTreeNeutralText);
		--jsonTreeValue:  var(--jsonTreeNeutralText);
		--jsonTreeUndefinedKey:  #999;
		--jsonTreePreviewColor:  #999;

	}
}

@mixin darkTheme {
    & {
		--textColor: #f5f5f5;
		--mainTextColor: var(--textColor);
		--textSoftColor: #aaa;
		--invertedTextColor: #000;
		--linkHoverColor: var(--contrastLightenedColor);

		--validationErrorTextColor: #d94f56;

		// --backgroundColor: #3f4047;
		// --contentColor: #232328;
		// --contentSoftColor: #2f2f35;
		// --backgroundSoftColor: #131316;
		// --softGreyColor: #55575e;
		// --greyColor: #7d818a;
		// --deepGreyColor: #868a94;

		--backgroundColor: #3a3e44;
		--contentColor: #24272c;
		--contentSoftColor: #292b31;
		--backgroundSoftColor: #131316;
		--backgroundSofterColor: #1b1c1f;
		--softGreyColor: #4f535c;
		--greyColor: #747b85;
		--deepGreyColor: #808992;

		--inputBgColor:#1b1c1f;
		--toolbarBgColor: var(--contentSoftColor);
		--accordionContentBgColor: var(--inputBgColor);

		--disabledBgColor: #31313a;
		--disabledTextColor: #999;
		--disabledBorderColor: #31313a;

		--warningColor: #e4b200;
		--dangerColor: #db204f;
		--dangerLightenedColor: #db3362;
		
		--overlaySoftColor: rgba(255, 255, 255, 0.1);
		--overlayDarkenColor: rgba(0, 0, 0, 0.3);
		--invertedOverlaySoftColor: rgba(0,0,0,.35);
		
		.ui-button.ui-button-secondary, .ui-buttonset.ui-button-secondary > .ui-button  {
			--buttonBgColor:var(--softGreyColor);
			--buttonBorderColor:var(--softGreyColor);
		}
		.ui-button.ui-button-disabled {
			--buttonBgColor: #50505a;
			--buttonHoverBgColor: #999;
		}
	
		--sidebarBgColor: var(--contentColor);
		--sidebarShadowColor: rgba(0,0,0,.6);
		--submenuBgColor: #17171e;
		--panelContentBorderColor:#2f2f31;

		--tableBodyRowBgColor: #2d3036;
		--tableBodyRowEvenBgColor: #31343a;
		--tableBodyRowTextColor: var(--textColor);		
		--tableGridHoverBgColor:var(--softGreyColor);
		--tableGridHoverTextColor:var(--contrastTextColor);
	
		--inputBorderColor:#2b2b32;

		--inputAutofillTextColor: var(--primaryTextColor);
		--inputAutofillBgColor: #4d5788;
	
		--inputDisabledBgColor: #303038;
		--inputDisabledTextColor: var(--textSoftColor);
		--inputDisabledBorderColor: #5d6065;

		--inputDropdownIconColor:var(--textColor);

		--orderListItemBorderColor:#353a3e;

		--inputOverlayBorderColor: var(--contentColor);
		--inputOverlayShadow:0 3px 8px 0 rgba(0, 0, 0, 1);
		--dialogShadow:0 0 25px 10px rgb(0 0 0 / 73%);

		--linkActiveColor: #fff;

		--scrollbarColor: #555;
		--scrollbarBgColor: #000;

		--boxHoverShadowColor: #000;

		// --jsonTreeNeutralText:  #eaeaea;
		// --jsonTreeTypeInfo:  #ff77c2;

    }
}


/**
 * next, generate the actual theme css var data based on the defined mixins.
 * wrapping of extras mixins within "& {}" is required to maintain correct rendering orders,
 * as the extras are supposed to overwrite base theme, which will only work if they are defined AFTER the base theme css vars. 
 *
 * lightTheme must be included for ANY [ui-theme] due to how css-vars work.
 * baseTheme/lightTheme contains many vars pointing to other vars.
 * unfortunately css-vars do not support true variable assignments,
 * so to make a child var value point to the "correct" base-color-var, the light theme has to be included once again.
 *
 * Setup:
 * - [ui-theme=*] default to light theme. (includes body default)
 * - [ui-theme=dark] is explicitly set to dark.
 * - [ui-theme=light] is handled by first rule.
 * - body[ui-theme=auto] is set to dark if prefers-color-scheme=dark. (includes body default)
 * - [ui-theme=auto] should not be set explicitly. Only used on <body> assuming user did not select a theme explicitly.
 * - [ui-theme=inherit-page] inherits the current page theme based on explicit user choice or prefers-color-scheme if in auto mode.
 */

body, [ui-theme] {
	@include lightTheme();
	&{
		@include platformExtrasLightTheme();
		@include appExtrasLightTheme();
	}
}

// used for theme mode = auto
// auto mode requires a media query, so it needs a separate/duplicated definition unfortunately.
@media (prefers-color-scheme: dark) {
	body[ui-theme="auto"],
	body[ui-theme="auto"] [ui-theme="inherit-page"]
	{ 
		@include darkTheme(); 
		&{
			@include appExtrasDarkTheme();
			@include platformExtrasDarkTheme();
		}
	}
}

[ui-theme="dark"],
body[ui-theme="dark"] [ui-theme="inherit-page"] {
	/**
	 * baseTheme/lightTheme contains many vars pointing to other vars.
	 * unfortunately css-vars do not support true variable assignments,
	 * so to make a child var value point to the "correct" base-color-var, the light theme has to be included once again.
	 */
	// @include lightTheme();
	@include darkTheme();
	&{
		@include appExtrasDarkTheme();
		@include platformExtrasDarkTheme();
	}
}

