@import 'node_modules/primeng/resources/components/messages/messages.css';


.p-message {
	padding: $messagePadding;
	margin: $messageMargin;
	border: $messageBorder;
	border-radius: $borderRadius;

	a { color:inherit; text-decoration:underline }
	.ui-button-outline { color: inherit; }

	&.p-message-info {
		background-color: var(--infoMessageBgColor);
		border-color: var(--infoMessageBorderColor);
		color: var(--infoMessageTextColor);

		.p-message-icon {
			color: var(--infoMessageIconColor);
		}
	}

	&.p-message-success {
		background-color: var(--successMessageBgColor);
		border-color: var(--successMessageBorderColor);
		color: var(--successMessageTextColor);

		.p-message-icon {
			color: var(--successMessageIconColor);
		}
	}

	&.p-message-warn {
		background-color: var(--warnMessageBgColor);
		border-color: var(--warnMessageBorderColor);
		color: var(--warnMessageTextColor);

		.p-message-icon {
			color: var(--warnMessageIconColor);
		}
	}

	&.p-message-error {
		background-color: var(--errorMessageBgColor);
		border-color: var(--errorMessageBorderColor);
		color: var(--errorMessageTextColor);

		.p-message-icon {
			color: var(--errorMessageIconColor);
		}
	}

	&.p-message-muted {
		background-color: var(--contentSoftColor);
		border-color: var(--softGreyColor);
		color: var(--deepGreyColor);

		.p-message-icon {
			color: var(--deepGreyColor);
		}
	}

}



