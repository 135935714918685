body {
	.p-component-overlay,
    .p-component-overlay {
        background-color: var(--maskBgColor);
    }

    .ui-overlaypanel {
        background-color: var(--panelContentBgColor);
        color: var(--panelContentTextColor);
        padding: 0;
        border: $overlayContentBorder;
		border-color: var(--overlayContentBorderColor);
		border-radius:0;

        .ui-overlaypanel-content {
            padding: $panelContentPadding;
        }

        .ui-overlaypanel-close {
            background-color: var(--overlayPanelCloseIconBgColor);
            color: var(--overlayPanelCloseIconColor);
            width: 1.538em;
            height: 1.538em;
            line-height: 1.538em;
            text-align: center;
            position: absolute;
            top: -1 * 1.538em * 0.5;
            right: -1 * 1.538em * 0.5;
            
            &:hover {
                background-color: var(--overlayPanelCloseIconHoverBgColor);
                color: var(--overlayPanelCloseIconHoverColor);
            }
        }

        &:after {
            border-color: rgba(var(--panelContentBgColor), 0);
            border-bottom-color: var(--panelContentBgColor);
        }

        &:before {
            border-color: rgba(var(--overlayContentBorderColor), 0);
            border-bottom-color: var(--overlayContentBorderColor);
        }

        &.ui-overlaypanel-flipped {
            &:after {
                border-top-color: var(--panelContentBgColor);
            }

            &:before {
                border-top-color: var(--overlayContentBorderColor);
            }
        }
    }

    

    .ui-sidebar {
        background-color: var(--panelContentBgColor);
        color: var(--panelContentTextColor);
        padding: $panelContentPadding;
        border: $overlayContentBorder;
		border-color: var(--overlayContentBorderColor);
		box-shadow:var(--inputOverlayShadow);
    }

    .ui-tooltip {
        .ui-tooltip-text {
            background-color: var(--tooltipBgColor);
            color: var(--tooltipTextColor);
            padding: $tooltipPadding;
			box-shadow:var(--inputOverlayShadow);
        }
        
        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                border-right-color: var(--tooltipBgColor);
            }
        }
        
        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: var(--tooltipBgColor);
            }
        }
        
        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                border-top-color: var(--tooltipBgColor);
            }
        }
        
        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                border-bottom-color: var(--tooltipBgColor);
            }
        }
    }

    .ui-lightbox {
		box-shadow:var(--dialogShadow);
        
        .ui-lightbox-caption {
            border: $panelHeaderBorder;
			border-color: var(--panelHeaderBorderColor);
			background-color: var(--panelHeaderBgColor);
            color: var(--panelHeaderTextColor);
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            
            
            .ui-lightbox-caption-text {
                color: var(--panelHeaderTextColor);
                margin: 0;
            }
            
            .ui-lightbox-close {
                padding: 0;
                color: var(--panelHeaderIconColor);
                transition:color $transitionDuration;
                
                &:hover {
                    color: var(--panelHeaderIconHoverColor);
                }
            }
        }

        .ui-lightbox-content-wrapper {
            overflow: hidden;
            background-color: var(--panelContentBgColor);
            color: var(--panelContentTextColor);
            border: 0 none;
            padding: 0;
            border-radius:0;
                      
            .ui-lightbox-nav-left, .ui-lightbox-nav-right {
                transition:all $transitionDuration;
                font-size: $lightBoxNavIconFontSize;
                color: var(--lightBoxNavIconColor);
                margin-top: -.5em;

                &:hover {
					transform:scale(1.2);
                }
            }
            
            .ui-lightbox-content.ui-lightbox-loading ~ a {
                display: none;
            }
        }
    }
}
