
* 								{ -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
html,body						{ height:100%;height:100vh; }

body {
    font-family: $fontFamily;
    font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
    color: var(--textColor);
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    background-color: var(--backgroundColor);
}

h1,h2,h3,h4,h5,h6,
.ui-h1,.ui-h2,.ui-h3,
.ui-h4,.ui-h5,.ui-h6 			{ font-family: $headlinesFontFamily; font-weight: $headlinesFontWeight; line-height: $headlinesLineHeight; 
									text-transform: $headlinesTextTransform; 
									color: var(--headlinesTextColor); margin:1em 0; }

a { 
	& 							{ color: var(--linkColor); text-decoration: none; cursor:pointer; font-weight:$linkFontWeight; }
	&:hover 					{ color: var(--linkHoverColor); }
	&:active 					{ color: var(--linkActiveColor); }
}

p 								{ margin:1em 0; }

hr 								{ border-color:var(--softGreyColor); margin:25px 0; }

h1,h2,h3,h4,h5,h6,p {
	&:first-child				{ margin-top:0; }
	&:last-child				{ margin-bottom:0; }
}

h1,.ui-h1						{ font-size:$fontSizeH1; }
h2,.ui-h2						{ font-size:$fontSizeH2; }
h3,.ui-h3						{ font-size:$fontSizeH3; }
h4,.ui-h4						{ font-size:$fontSizeH4; }
h5,.ui-h5						{ font-size:$fontSizeH5; }
h6,.ui-h6						{ font-size:$fontSizeH6; }
small							{ font-weight:$smallFontWeight; font-size:var(--fontSizeSmall); }
small a							{ font-weight:$smallLinkFontWeight; } // as <a> tends to be colorized, a small-text a can be hard to read. Use bold letters to compensate
.ui-text-small	 				{ font-weight:$smallFontWeight; font-size:var(--fontSizeSmall); }
button							{ font-weight:$buttonFontWeight; font-size:inherit; }

.p-component,
.p-component .p-component 			{ font-family: $fontFamily; text-decoration: none; }

// .p-reset 				{ line-height: normal; }

.p-disabled, .p-component:disabled { opacity:$disabledOpacity; }

.ui-unstyled-list				{ padding:0; list-style:none; }

img 							{ display:block; max-width:100%; }
svg 							{ display:block; height:auto; }

.p-component, .p-component *		{ box-sizing: border-box; }

.p-hidden 						{ display: none !important; }
.p-hidden-accessible 			{ border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.p-hidden-accessible input,
.p-hidden-accessible select 	{ transform: scale(0); }
// .p-reset 					{ margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none; }
.ui-clearfix::before,
.ui-clearfix::after 			{ content: ""; display: table; }
.ui-clearfix::after 			{ clear: both; }
.ui-clearfix 					{ zoom: 1; }
.ui-helper-zfix 				{ width: 100%; height: 100%; top: 0; left: 0; position: absolute; opacity: 0; filter: Alpha(Opacity=0); }
.p-disabled 					{ cursor: default !important; }
.p-disabled a 					{ cursor: default !important; }
.ui-icon 						{ display: block; text-indent: -99999px; overflow: hidden; background-repeat: no-repeat; }
.p-component-overlay 				{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.ui-resizable 					{ position: relative; }
.ui-resizable-handle 			{ position: absolute; font-size: 0.1px; display: block; }
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle { display: none; }

.ui-resizable-n 				{ cursor: n-resize; height: 7px; width: 100%; top: -5px; left: 0; }
.ui-resizable-s 				{ cursor: s-resize; height: 7px; width: 100%; bottom: -5px; left: 0; }
.ui-resizable-e 				{ cursor: e-resize; width: 7px; right: -5px; top: 0; height: 100%; }
.ui-resizable-w 				{ cursor: w-resize; width: 7px; left: -5px; top: 0; height: 100%; }
.ui-resizable-se				{ cursor: se-resize; width: 12px; height: 12px; right: 1px; bottom: 1px; }
.ui-resizable-sw				{ cursor: sw-resize; width: 9px; height: 9px; left: -5px; bottom: -5px; }
.ui-resizable-nw				{ cursor: nw-resize; width: 9px; height: 9px; left: -5px; top: -5px; }
.ui-resizable-ne				{ cursor: ne-resize; width: 9px; height: 9px; right: -5px; top: -5px; }

.ui-shadow 						{ -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3); -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3); 
									box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3); }
.ui-unselectable-text 			{ -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -o-user-select: none; user-select: none; }
.ui-scrollbar-measure 			{ width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px; }
.ui-overflow-hidden 			{ overflow: hidden; }

::-webkit-input-placeholder 	{ /* WebKit, Blink, Edge */ color: #898989; }
:-moz-placeholder 				{ /* Mozilla Firefox 4 to 18 */ color: #898989; opacity:  1; }
::-moz-placeholder 				{ /* Mozilla Firefox 19+ */ color: #898989; opacity:  1; }
:-ms-input-placeholder 			{ /* Internet Explorer 10-11 */ color: #898989; }
::-ms-input-placeholder 		{ /* Microsoft Edge */ color: #898989; }
.ui-placeholder 				{ color: #898989; }

input[type="button"], 
input[type="submit"], 
input[type="reset"], 
input[type="file"]::-webkit-file-upload-button, 
button 							{ -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; }

// new in Chrome 83 - https://support.google.com/chrome/thread/48974735?hl=en
*, input, select, textarea, option, button { outline: none; font-family:inherit; }