.ui-iconlist {
	& 							{ list-style:none; padding:0; margin:10px 0 30px }
	&>li 						{ margin:9px 0; position:relative; padding-left:30px; }
	&>li ui-icon 				{ width:16px; display: block; vertical-align:middle; position:absolute; left:0; top:0;bottom:0; }

	&.medium {
		&		 				{ font-size:1.1rem }
		@media(min-width:1600px) {
			&		 				{ font-size:1.3rem }
		}
	}
}