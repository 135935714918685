
    ::-webkit-input-placeholder {color:var(--inputPlaceholderTextColor)}
    :-moz-placeholder           {color:var(--inputPlaceholderTextColor)}
    ::-moz-placeholder          {color:var(--inputPlaceholderTextColor)}
    :-ms-input-placeholder      {color:var(--inputPlaceholderTextColor)}

    /* Validations */
    .p-input-text.ng-dirty.ng-invalid,
    ui-input-dropdown.ng-dirty.ng-invalid > .p-dropdown,
    ui-input-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-input-text,
    ui-calendar.ng-dirty.ng-invalid > .p-calendar > .p-input-text,
    ui-chips.ng-dirty.ng-invalid > .p-input-text,
    // p-inputmask.ng-dirty.ng-invalid > .p-input-text,
    ui-input-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
    ui-input-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
    ui-input-switch.ng-dirty.ng-invalid .p-inputswitch,
    ui-input-listbox.ng-dirty.ng-invalid .p-input-text,
    ui-input-multiselect.ng-dirty.ng-invalid > .p-multiselect,
    // p-spinner.ng-dirty.ng-invalid > .p-input-text,
    ui-input-selectbutton.ng-dirty.ng-invalid .ui-button,
    ui-input-togglebutton.ng-dirty.ng-invalid .ui-button {
        border-color: var(--inputErrorBorderColor);
    }
