.color-primary 			{ color:var(--primaryColor)!important }
.color-primary-dark 	{ color:var(--primaryDarkColor)!important }
.color-primary-darker 	{ color:var(--primaryDarkerColor)!important }
.color-highlight 		{ color:var(--highlightBgColor)!important }
.color-contrast 		{ color:var(--contrastColor)!important }
.color-focus 			{ color:var(--inputFocusBorderColor)!important }
.color-info 			{ color:var(--infoColor)!important }
.color-success 			{ color:var(--successColor)!important }
.color-grey 			{ color:var(--greyColor)!important }
.color-warning 			{ color:var(--warningColor)!important }
.color-danger 			{ color:var(--dangerColor)!important }

.box-primary 			{ background-color:var(--primaryColor)!important; color:var(--primaryTextColor)!important; }
.box-primary-dark 		{ background-color:var(--primaryDarkColor)!important; color:var(--primaryTextColor)!important; }
.box-primary-darker 	{ background-color:var(--primaryDarkerColor)!important; color:var(--primaryTextColor)!important; }
.box-contrast		 	{ background-color:var(--contrastColor)!important; color:var(--primaryTextColor)!important; }
.box-secondary		 	{ background-color:var(--secondaryColor)!important; color:var(--primaryTextColor)!important; }
.box-highlight 			{ background-color:var(--highlightBgColor)!important; color:var(--highlightTextColor)!important; }
.box-content 			{ background-color:var(--contentColor)!important; color:var(--textColor)!important; }
.box-content-soft		{ background-color:var(--contentSoftColor)!important; color:var(--textColor)!important; }
.box-soft-grey			{ background-color:var(--softGreyColor)!important; color:var(--textColor)!important; }
