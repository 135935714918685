// these are general layout classes, those specific to ui-grid are located within the component styles!

.p-dir-row 					{ -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; }
.p-dir-rev 					{ -webkit-box-orient: horizontal; -webkit-box-direction: reverse; -ms-flex-direction: row-reverse; flex-direction: row-reverse; }
.p-dir-col 					{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
.p-dir-col-rev 				{ -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-direction: column-reverse; flex-direction: column-reverse; }

.p-justify-start 			{ -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
.p-justify-end 				{ -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; }
.p-justify-center 			{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
.p-justify-between 			{ -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
.p-justify-around 			{ -ms-flex-pack: distribute; justify-content: space-around; }
.p-justify-even 			{ -webkit-box-pack: space-evenly; -ms-flex-pack: space-evenly; justify-content: space-evenly; }

.p-align-start 				{ -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
.p-align-end 				{ -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
.p-align-center 			{ -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.p-align-baseline 			{ -webkit-box-align: baseline; -ms-flex-align: baseline; align-items: baseline; }
.p-align-stretch 			{ -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; }

// .p-col 						{ -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-preferred-size: 0; flex-basis: 0; 
// 								padding-left: $gridSpacing; margin-top:$gridSpacing; }
// .p-col-fixed				{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; padding-left: $gridSpacing; margin-top:$gridSpacing; }

.p-col-align-start 			{ -ms-flex-item-align: start; align-self: flex-start; }
.p-col-align-end 			{ -ms-flex-item-align: end; align-self: flex-end; }
.p-col-align-center 		{ -ms-flex-item-align: center; -ms-grid-row-align: center; align-self: center; }
.p-col-align-baseline 		{ -ms-flex-item-align: baseline; align-self: baseline; }
.p-col-align-stretch 		{ -ms-flex-item-align: stretch; -ms-grid-row-align: stretch; align-self: stretch; }

// .p-dir-col > .p-col,
// .p-dir-col-rev > .p-col 	{ -ms-flex-preferred-size: auto; flex-basis: auto; }

.p-grid						{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; 
								margin-left: -1 * $gridSpacing; width:calc( 100% + #{$gridSpacing} ) }

.p-grid {
	&>[class*="p-col"] 			{ box-sizing: border-box; padding-left: $gridSpacing; }
	&>.p-col 					{ flex-grow: 1; flex-basis: 0; }
	
	&>.p-col-fixed				{ flex: 0 0 auto; padding-left: $gridSpacing; }

	&>[class*=p-sm-],
	&>[class*=p-md-],
	&>[class*=p-lg-],
	&>[class*=p-xl-],
	&>[class*=p-xxl-] 			{ padding-left: $gridSpacing; flex:unset; }
	&>.ui-grid-row-margin		{ margin-top:$gridSpacing; }
	& + .ui-grid				{ margin-top:$gridSpacing }

	&.match-height>*			{ display: flex; flex-direction:column; flex:none }

	&.ui-grid-large {
		&				 		{ margin-left: -1 * $gridSpacingLarge; width:calc( 100% + #{$gridSpacingLarge} ) }
		&>.p-col,
		&>.p-col-fixed,
		&>[class*="p-col-"],
		&>[class*=p-sm-],
		&>[class*=p-md-],
		&>[class*=p-lg-],
		&>[class*=p-xl-],
		&>[class*=p-xxl-] 		{ padding-left: $gridSpacingLarge; }
		&>.ui-grid-row-margin	{ margin-top:$gridSpacingLarge; }
		& + .ui-grid			{ margin-top:$gridSpacingLarge }
	}
	
	&.ui-grid-small{
		&				 		{ margin-left: -1 * $gridSpacingSmall; width:calc( 100% + #{$gridSpacingSmall} ) }
		&>.p-col,
		&>.p-col-fixed,
		&>[class*="p-col-"],
		&>[class*=p-sm-],
		&>[class*=p-md-],
		&>[class*=p-lg-],
		&>[class*=p-xl-],
		&>[class*=p-xxl-] 		{ padding-left: $gridSpacingSmall; }
		&>.ui-grid-row-margin	{ margin-top:$gridSpacingSmall; }
		& + .ui-grid			{ margin-top:$gridSpacingSmall }
	}
	
	&.ui-grid-collapse {
		& 						{ margin: 0; width:100% }
		&>.p-col,
		&>.p-col-fixed,
		&>[class*="p-col-"],
		&>[class*=p-sm-],
		&>[class*=p-md-],
		&>[class*=p-lg-],
		&>[class*=p-xl-],
		&>[class*=p-xxl-] 		{ padding:0;margin:0; }
		&>.ui-grid-row-margin	{ margin-top:0; }
		& + .ui-grid			{ margin-top:0 }
	}
}


.p-col,
.p-col-fixed,
[class*="p-col-"],
[class*=p-sm-],
[class*=p-md-],
[class*=p-lg-],
[class*=p-xl-],
[class*=p-xxl-] 			{ width:100% }

// .p-col-nogutter 			{ padding-left: 0; }

.p-col-1 			{ width: 8.3333%; }
.p-col-2 			{ width: 16.6667%; }
.p-col-3 			{ width: 25%; }
.p-col-4 			{ width: 33.3333%; }
.p-col-5 			{ width: 41.6667%; }
.p-col-6 			{ width: 50%; }
.p-col-7 			{ width: 58.3333%; }
.p-col-8 			{ width: 66.6667%; }
.p-col-9 			{ width: 75%; }
.p-col-10 			{ width: 83.3333%; }
.p-col-11 			{ width: 91.6667%; }
.p-col-12 			{ width: 100%; }

.p-col-order-first 	{ -ms-flex-order: -1; order: -1; }
.p-col-order-last 	{ -ms-flex-order: 13; order: 13; }
.p-col-order-0 		{ -ms-flex-order: 0; order: 0; }
.p-col-order-1 		{ -ms-flex-order: 1; order: 1; }
.p-col-order-2 		{ -ms-flex-order: 2; order: 2; }
.p-col-order-3 		{ -ms-flex-order: 3; order: 3; }
.p-col-order-4 		{ -ms-flex-order: 4; order: 4; }
.p-col-order-5 		{ -ms-flex-order: 5; order: 5; }
.p-col-order-6 		{ -ms-flex-order: 6; order: 6; }
.p-col-order-7 		{ -ms-flex-order: 7; order: 7; }
.p-col-order-8 		{ -ms-flex-order: 8; order: 8; }
.p-col-order-9 		{ -ms-flex-order: 9; order: 9; }
.p-col-order-10		{ -ms-flex-order: 10; order: 10; }
.p-col-order-11		{ -ms-flex-order: 11; order: 11; }
.p-col-order-12		{ -ms-flex-order: 12; order: 12; }

.p-offset-12 		{ margin-left: 100%; }
.p-offset-11 		{ margin-left: 91.66666667%; }
.p-offset-10 		{ margin-left: 83.33333333%; }
.p-offset-9 		{ margin-left: 75%; }
.p-offset-8 		{ margin-left: 66.66666667%; }
.p-offset-7 		{ margin-left: 58.33333333%; }
.p-offset-6 		{ margin-left: 50%; }
.p-offset-5 		{ margin-left: 41.66666667%; }
.p-offset-4 		{ margin-left: 33.33333333%; }
.p-offset-3 		{ margin-left: 25%; }
.p-offset-2 		{ margin-left: 16.66666667%; }
.p-offset-1 		{ margin-left: 8.33333333%; }
.p-offset-0 		{ margin-left: 0%; }



@media screen and (min-width: $smallBreakpoint) {

	.p-sm-1,
	.p-sm-2,
	.p-sm-3,
	.p-sm-4,
	.p-sm-5,
	.p-sm-6,
	.p-sm-7,
	.p-sm-8,
	.p-sm-9,
	.p-sm-10,
	.p-sm-11,
	.p-sm-12 			{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	
	.p-sm-1 			{ width: 8.3333%; }
	.p-sm-2 			{ width: 16.6667%; }
	.p-sm-3 			{ width: 25%; }
	.p-sm-4 			{ width: 33.3333%; }
	.p-sm-5 			{ width: 41.6667%; }
	.p-sm-6 			{ width: 50%; }
	.p-sm-7 			{ width: 58.3333%; }
	.p-sm-8 			{ width: 66.6667%; }
	.p-sm-9 			{ width: 75%; }
	.p-sm-10 			{ width: 83.3333%; }
	.p-sm-11 			{ width: 91.6667%; }
	.p-sm-12 			{ width: 100%; }

	.p-sm-offset-12 	{ margin-left: 100%; }
	.p-sm-offset-11 	{ margin-left: 91.66666667%; }
	.p-sm-offset-10 	{ margin-left: 83.33333333%; }
	.p-sm-offset-9 		{ margin-left: 75%; }
	.p-sm-offset-8 		{ margin-left: 66.66666667%; }
	.p-sm-offset-7 		{ margin-left: 58.33333333%; }
	.p-sm-offset-6 		{ margin-left: 50%; }
	.p-sm-offset-5 		{ margin-left: 41.66666667%; }
	.p-sm-offset-4 		{ margin-left: 33.33333333%; }
	.p-sm-offset-3 		{ margin-left: 25%; }
	.p-sm-offset-2 		{ margin-left: 16.66666667%; }
	.p-sm-offset-1 		{ margin-left: 8.33333333%; }
	.p-sm-offset-0 		{ margin-left: 0%; }
	
	.p-sm-order-first 	{ -ms-flex-order: -1; order: -1; }
	.p-sm-order-last 	{ -ms-flex-order: 13; order: 13; }
	.p-sm-order-0 		{  -ms-flex-order: 0; order: 0; }
	.p-sm-order-1 		{ -ms-flex-order: 1; order: 1; }
	.p-sm-order-2 		{ -ms-flex-order: 2; order: 2; }
	.p-sm-order-3 		{ -ms-flex-order: 3; order: 3; }
	.p-sm-order-4 		{ -ms-flex-order: 4; order: 4; }
	.p-sm-order-5 		{ -ms-flex-order: 5; order: 5; }
	.p-sm-order-6 		{ -ms-flex-order: 6; order: 6; }
	.p-sm-order-7 		{  -ms-flex-order: 7; order: 7; }
	.p-sm-order-8 		{ -ms-flex-order: 8; order: 8; }
	.p-sm-order-9 		{ -ms-flex-order: 9; order: 9; }
	.p-sm-order-10 		{ -ms-flex-order: 10; order: 10; }
	.p-sm-order-11 		{ -ms-flex-order: 11; order: 11; }
	.p-sm-order-12 		{ -ms-flex-order: 12;  order: 12; }
}	

@media screen and (min-width: $mobileBreakpoint) {

	.p-md-1,
	.p-md-2,
	.p-md-3,
	.p-md-4,
	.p-md-5,
	.p-md-6,
	.p-md-7,
	.p-md-8,
	.p-md-9,
	.p-md-10,
	.p-md-11,
	.p-md-12 			{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	
	.p-md-1 			{ width: 8.3333%; }
	.p-md-2 			{ width: 16.6667%; }
	.p-md-3 			{ width: 25%; }
	.p-md-4 			{ width: 33.3333%; }
	.p-md-5 			{ width: 41.6667%; }
	.p-md-6 			{ width: 50%; }
	.p-md-7 			{ width: 58.3333%; }
	.p-md-8 			{ width: 66.6667%; }
	.p-md-9 			{ width: 75%; }
	.p-md-10 			{ width: 83.3333%; }
	.p-md-11 			{ width: 91.6667%; }
	.p-md-12 			{ width: 100%; }

	.p-md-offset-12 	{ margin-left: 100%; }
	.p-md-offset-11 	{ margin-left: 91.66666667%; }
	.p-md-offset-10 	{ margin-left: 83.33333333%; }
	.p-md-offset-9 		{ margin-left: 75%; }
	.p-md-offset-8 		{ margin-left: 66.66666667%; }
	.p-md-offset-7 		{ margin-left: 58.33333333%; }
	.p-md-offset-6 		{ margin-left: 50%; }
	.p-md-offset-5 		{ margin-left: 41.66666667%; }
	.p-md-offset-4 		{ margin-left: 33.33333333%; }
	.p-md-offset-3 		{ margin-left: 25%; }
	.p-md-offset-2 		{ margin-left: 16.66666667%; }
	.p-md-offset-1 		{ margin-left: 8.33333333%; }
	.p-md-offset-0 		{ margin-left: 0%; }
	
	.p-md-order-first 	{ -ms-flex-order: -1; order: -1; }
	.p-md-order-last 	{ -ms-flex-order: 13; order: 13; }
	.p-md-order-0 		{  -ms-flex-order: 0; order: 0; }
	.p-md-order-1 		{ -ms-flex-order: 1; order: 1; }
	.p-md-order-2 		{ -ms-flex-order: 2; order: 2; }
	.p-md-order-3 		{ -ms-flex-order: 3; order: 3; }
	.p-md-order-4 		{ -ms-flex-order: 4; order: 4; }
	.p-md-order-5 		{ -ms-flex-order: 5; order: 5; }
	.p-md-order-6 		{ -ms-flex-order: 6; order: 6; }
	.p-md-order-7 		{  -ms-flex-order: 7; order: 7; }
	.p-md-order-8 		{ -ms-flex-order: 8; order: 8; }
	.p-md-order-9 		{ -ms-flex-order: 9; order: 9; }
	.p-md-order-10 		{ -ms-flex-order: 10; order: 10; }
	.p-md-order-11 		{ -ms-flex-order: 11; order: 11; }
	.p-md-order-12 		{ -ms-flex-order: 12;  order: 12; }
}	

@media screen and (min-width: $tabletBreakpoint) {

	.p-lg-1,
	.p-lg-2,
	.p-lg-3,
	.p-lg-4,
	.p-lg-5,
	.p-lg-6,
	.p-lg-7,
	.p-lg-8,
	.p-lg-9,
	.p-lg-10,
	.p-lg-11,
	.p-lg-12 			{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	
	.p-lg-1 			{ width: 8.3333%; }
	.p-lg-2 			{ width: 16.6667%; }
	.p-lg-3 			{ width: 25%; }
	.p-lg-4 			{ width: 33.3333%; }
	.p-lg-5 			{ width: 41.6667%; }
	.p-lg-6 			{ width: 50%; }
	.p-lg-7 			{ width: 58.3333%; }
	.p-lg-8 			{ width: 66.6667%; }
	.p-lg-9 			{ width: 75%; }
	.p-lg-10 			{ width: 83.3333%; }
	.p-lg-11 			{ width: 91.6667%; }
	.p-lg-12 			{ width: 100%; }

	.p-lg-offset-12 	{ margin-left: 100%; }
	.p-lg-offset-11 	{ margin-left: 91.66666667%; }
	.p-lg-offset-10 	{ margin-left: 83.33333333%; }
	.p-lg-offset-9 		{ margin-left: 75%; }
	.p-lg-offset-8 		{ margin-left: 66.66666667%; }
	.p-lg-offset-7 		{ margin-left: 58.33333333%; }
	.p-lg-offset-6 		{ margin-left: 50%; }
	.p-lg-offset-5 		{ margin-left: 41.66666667%; }
	.p-lg-offset-4 		{ margin-left: 33.33333333%; }
	.p-lg-offset-3 		{ margin-left: 25%; }
	.p-lg-offset-2 		{ margin-left: 16.66666667%; }
	.p-lg-offset-1 		{ margin-left: 8.33333333%; }
	.p-lg-offset-0 		{ margin-left: 0%; }
	
	.p-lg-order-first 	{ -ms-flex-order: -1; order: -1; }
	.p-lg-order-last 	{ -ms-flex-order: 13; order: 13; }
	.p-lg-order-0 		{  -ms-flex-order: 0; order: 0; }
	.p-lg-order-1 		{ -ms-flex-order: 1; order: 1; }
	.p-lg-order-2 		{ -ms-flex-order: 2; order: 2; }
	.p-lg-order-3 		{ -ms-flex-order: 3; order: 3; }
	.p-lg-order-4 		{ -ms-flex-order: 4; order: 4; }
	.p-lg-order-5 		{ -ms-flex-order: 5; order: 5; }
	.p-lg-order-6 		{ -ms-flex-order: 6; order: 6; }
	.p-lg-order-7 		{  -ms-flex-order: 7; order: 7; }
	.p-lg-order-8 		{ -ms-flex-order: 8; order: 8; }
	.p-lg-order-9 		{ -ms-flex-order: 9; order: 9; }
	.p-lg-order-10 		{ -ms-flex-order: 10; order: 10; }
	.p-lg-order-11 		{ -ms-flex-order: 11; order: 11; }
	.p-lg-order-12 		{ -ms-flex-order: 12;  order: 12; }
}	

@media screen and (min-width: $largeBreakpoint) {

	.p-xl-1,
	.p-xl-2,
	.p-xl-3,
	.p-xl-4,
	.p-xl-5,
	.p-xl-6,
	.p-xl-7,
	.p-xl-8,
	.p-xl-9,
	.p-xl-10,
	.p-xl-11,
	.p-xl-12 			{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	
	.p-xl-1 			{ width: 8.3333%; }
	.p-xl-2 			{ width: 16.6667%; }
	.p-xl-3 			{ width: 25%; }
	.p-xl-4 			{ width: 33.3333%; }
	.p-xl-5 			{ width: 41.6667%; }
	.p-xl-6 			{ width: 50%; }
	.p-xl-7 			{ width: 58.3333%; }
	.p-xl-8 			{ width: 66.6667%; }
	.p-xl-9 			{ width: 75%; }
	.p-xl-10 			{ width: 83.3333%; }
	.p-xl-11 			{ width: 91.6667%; }
	.p-xl-12 			{ width: 100%; }

	.p-xl-offset-12 	{ margin-left: 100%; }
	.p-xl-offset-11 	{ margin-left: 91.66666667%; }
	.p-xl-offset-10 	{ margin-left: 83.33333333%; }
	.p-xl-offset-9 		{ margin-left: 75%; }
	.p-xl-offset-8 		{ margin-left: 66.66666667%; }
	.p-xl-offset-7 		{ margin-left: 58.33333333%; }
	.p-xl-offset-6 		{ margin-left: 50%; }
	.p-xl-offset-5 		{ margin-left: 41.66666667%; }
	.p-xl-offset-4 		{ margin-left: 33.33333333%; }
	.p-xl-offset-3 		{ margin-left: 25%; }
	.p-xl-offset-2 		{ margin-left: 16.66666667%; }
	.p-xl-offset-1 		{ margin-left: 8.33333333%; }
	.p-xl-offset-0 		{ margin-left: 0%; }
	
	.p-xl-order-first 	{ -ms-flex-order: -1; order: -1; }
	.p-xl-order-last 	{ -ms-flex-order: 13; order: 13; }
	.p-xl-order-0 		{  -ms-flex-order: 0; order: 0; }
	.p-xl-order-1 		{ -ms-flex-order: 1; order: 1; }
	.p-xl-order-2 		{ -ms-flex-order: 2; order: 2; }
	.p-xl-order-3 		{ -ms-flex-order: 3; order: 3; }
	.p-xl-order-4 		{ -ms-flex-order: 4; order: 4; }
	.p-xl-order-5 		{ -ms-flex-order: 5; order: 5; }
	.p-xl-order-6 		{ -ms-flex-order: 6; order: 6; }
	.p-xl-order-7 		{  -ms-flex-order: 7; order: 7; }
	.p-xl-order-8 		{ -ms-flex-order: 8; order: 8; }
	.p-xl-order-9 		{ -ms-flex-order: 9; order: 9; }
	.p-xl-order-10 		{ -ms-flex-order: 10; order: 10; }
	.p-xl-order-11 		{ -ms-flex-order: 11; order: 11; }
	.p-xl-order-12 		{ -ms-flex-order: 12;  order: 12; }
}	

@media screen and (min-width: $xlargeBreakpoint) {

	.p-xxl-1,
	.p-xxl-2,
	.p-xxl-3,
	.p-xxl-4,
	.p-xxl-5,
	.p-xxl-6,
	.p-xxl-7,
	.p-xxl-8,
	.p-xxl-9,
	.p-xxl-10,
	.p-xxl-11,
	.p-xxl-12 			{ -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	
	.p-xxl-1 			{ width: 8.3333%; }
	.p-xxl-2 			{ width: 16.6667%; }
	.p-xxl-3 			{ width: 25%; }
	.p-xxl-4 			{ width: 33.3333%; }
	.p-xxl-5 			{ width: 41.6667%; }
	.p-xxl-6 			{ width: 50%; }
	.p-xxl-7 			{ width: 58.3333%; }
	.p-xxl-8 			{ width: 66.6667%; }
	.p-xxl-9 			{ width: 75%; }
	.p-xxl-10 			{ width: 83.3333%; }
	.p-xxl-11 			{ width: 91.6667%; }
	.p-xxl-12 			{ width: 100%; }

	.p-xxl-offset-12 	{ margin-left: 100%; }
	.p-xxl-offset-11 	{ margin-left: 91.66666667%; }
	.p-xxl-offset-10 	{ margin-left: 83.33333333%; }
	.p-xxl-offset-9 	{ margin-left: 75%; }
	.p-xxl-offset-8 	{ margin-left: 66.66666667%; }
	.p-xxl-offset-7 	{ margin-left: 58.33333333%; }
	.p-xxl-offset-6 	{ margin-left: 50%; }
	.p-xxl-offset-5 	{ margin-left: 41.66666667%; }
	.p-xxl-offset-4 	{ margin-left: 33.33333333%; }
	.p-xxl-offset-3 	{ margin-left: 25%; }
	.p-xxl-offset-2 	{ margin-left: 16.66666667%; }
	.p-xxl-offset-1 	{ margin-left: 8.33333333%; }
	.p-xxl-offset-0 	{ margin-left: 0%; }
	
	.p-xxl-order-first 	{ -ms-flex-order: -1; order: -1; }
	.p-xxl-order-last 	{ -ms-flex-order: 13; order: 13; }
	.p-xxl-order-0 		{  -ms-flex-order: 0; order: 0; }
	.p-xxl-order-1 		{ -ms-flex-order: 1; order: 1; }
	.p-xxl-order-2 		{ -ms-flex-order: 2; order: 2; }
	.p-xxl-order-3 		{ -ms-flex-order: 3; order: 3; }
	.p-xxl-order-4 		{ -ms-flex-order: 4; order: 4; }
	.p-xxl-order-5 		{ -ms-flex-order: 5; order: 5; }
	.p-xxl-order-6 		{ -ms-flex-order: 6; order: 6; }
	.p-xxl-order-7 		{  -ms-flex-order: 7; order: 7; }
	.p-xxl-order-8 		{ -ms-flex-order: 8; order: 8; }
	.p-xxl-order-9 		{ -ms-flex-order: 9; order: 9; }
	.p-xxl-order-10 	{ -ms-flex-order: 10; order: 10; }
	.p-xxl-order-11 	{ -ms-flex-order: 11; order: 11; }
	.p-xxl-order-12 	{ -ms-flex-order: 12;  order: 12; }
}	
