.p-input {
	font-size: $fontSize;
	color: var(--textColor);
	background: var(--inputBgColor);
	padding: $inputPadding;
	border: $inputBorder;
	border-color: var(--inputBorderColor);
	vertical-align:middle;
	transition:$inputTransition;
	@include appearance(none);
	
	&[readonly], &[disabled] { @include disabled-input(); }

	&:enabled:hover:not([readonly]) {
		border-color: var(--inputHoverBorderColor);
		background-color: var(--inputHoverBgColor);
	}

	&:enabled:focus:not([readonly]) {
		@include focused-input();
	}

	&.ng-invalid {
		border-color: var(--inputErrorBorderColor);
	}
}


.p-input-text {
	font-family:inherit;
	font-size: $fontSize;
	color: var(--inputTextColor);
	background: var(--inputBgColor);
	padding: $inputPadding;
	padding: var(--inputPadding);
	border: $inputBorder;
	border-radius: var(--borderLeftRadius);
	border-top-right-radius:var(--borderRightRadius) ;
	border-bottom-right-radius:var(--borderRightRadius) ;
	border-color: var(--inputBorderColor);
	vertical-align:middle;
	transition:$inputTransition;
	@include appearance(none);
	
	&[readonly], &[disabled] { @include disabled-input(); }

	&:enabled:hover:not([readonly]) {
		border-color: var(--inputHoverBorderColor);
		background-color: var(--inputHoverBgColor);
	}

	&:enabled:focus:not([readonly]) {
		@include focused-input();
	}

	&.ng-invalid {
		border-color: var(--inputErrorBorderColor);
	}

	@include autofill {
		-webkit-box-shadow: 0 0 0 30px var(--inputAutofillBgColor) inset !important; 
		color:var(--inputAutofillTextColor)!important;
		-webkit-text-fill-color: var(--inputAutofillTextColor);
	}
}
