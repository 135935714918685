.ui-virtualscroller {
	.ui-virtualscroller-header {
		background-color: var(--panelHeaderBgColor);
		color: var(--panelHeaderTextColor);
		border: $panelHeaderBorder;
		border-color: var(--panelHeaderBorderColor);
		padding: $panelHeaderPadding;
		font-weight: $panelHeaderFontWeight;
		border-bottom: 0 none;
	}

	.ui-virtualscroller-content {
		border: $panelContentBorder;
		background-color: var(--panelContentBgColor);
		color: var(--panelContentTextColor);
		.ui-virtualscroller-list {
			li {
				border-bottom: $panelContentBorder;
				border-color: var(--panelContentBorderColor);
			}
		}
	}

	.ui-virtualscroller-footer {
		background-color: var(--panelFooterBgColor);
		color: var(--panelFooterTextColor);
		border: $panelFooterBorder;
		border-color: var(--panelFooterBorderColor);
		padding: $panelFooterPadding;
		border-top: 0 none;
	}
}
